import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FormControl,FormGroup } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseUtils } from '@fuse/utils';
import { navigation } from 'app/navigation/navigation';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { Mail } from 'app/main/dashboard/dashboard.model';
import { Member } from 'app/main/dashboard/member.model';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TicketDialogComponent } from 'app/main/dashboard/dialogs/ticket/ticket.component';
import { ReportMemberDialogComponent } from 'app/main/dashboard/dialogs/report-member/report-member.component';
import { BlockMemberDialogComponent } from 'app/main/dashboard/dialogs/block-member/block-member.component';
import { DashboardDialogComponent } from 'app/main/dashboard/dashboard-dialog.component';
import { DashboardComponent } from 'app/main/dashboard/dashboard.component';
import { DashboardComposeDialogComponent } from 'app/main/dashboard/dialogs/compose/compose.component';
import { ContactDialogComponent } from 'app/main/dashboard/dialogs/contact/contact.component';
import { PolicyDialogComponent } from 'app/main/dashboard/dialogs/policy/policy.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MemberSearchDialogComponent } from 'app/main/dashboard/dialogs/member-search/member-search.component';
import { DatetimeSearchDialogComponent } from 'app/main/dashboard/dialogs/datetime-search/datetime-search.component';
import $ from 'jquery';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    company:any='';
    image:any='';
    timeout: any = null;
    searchtext: FormControl;
    mails:any=Mail;
    members:any=Member;
    dialogRef: any;
    filtered=0;
    target:any;
    role: any;
    

    @ViewChild(MatMenuTrigger,{static:false}) menu: MatMenuTrigger;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _mailService: MailService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        public _matDialog: MatDialog,
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];
       
        this.image=localStorage.getItem('image');
        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.searchtext=new FormControl("");
        this.role=localStorage.getItem('Role');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    openmenu(event){
        event.preventDefault();
        this.menu.openMenu()
    }
    closemenu(event){
        event.preventDefault();
        console.log('close');
        this.menu.closeMenu()
    }
    ngOnInit(): void
    {
        console.log('toolbar');
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        this.company=localStorage.getItem('CompanyName');
        if( this.company==null){
            this.company='';
        }
        console.log(this.company+'company');
        
        //
        // this.searchtext.valueChanges.pipe(
        //     // takeUntil(this._unsubscribeAll),
        //     debounceTime(300),
        //     distinctUntilChanged()
        // )
        //     .subscribe(searchText => {
        //         console.log(searchText,' -- searchText');
        //         this._mailService.searchText=searchText;
        //         this._mailService.onSearchTextChanged.next(searchText);
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        // this._fuseSidebarService.getSidebar(key).toggleOpen();
        $(document).ready(function(){
            $('.mail-main-sidebar').toggleClass('open');
            // $('.mail-main-sidebar').toggle(function () {
            //     $(this).css({'visibility': "hidden",'box-shadow': 'none','display':'none'});
            // }, function () {
            //     $(this).css({'visibility': "visible",'box-shadow': 'none','display':'block'});
            // });
        });
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
           // if (event.keyCode != 13) {
                if($this ._mailService.memberview){
                    if(typeof value!='undefined' && typeof value.target!='undefined' ){
                        // if(value.target.value=='' || value.target.value==null){
                        //     $this._mailService.getDashboardMembers(value.target.value);
                        // }else{
                            $this .members = FuseUtils.filterArrayByString($this ._mailService.Members, value.target.value);
                        // }
                            $this._mailService.onMembersChanged.next($this.members);
                    }
                }else{
                    if(typeof value!='undefined' && typeof value.target!='undefined' ){
                        // if(value.target.value=='' || value.target.value==null){
                        //     $this._mailService.getMails(value.target.value);
                        // }else{
                            $this.mails = FuseUtils.filterArrayByString($this._mailService.mails, value.target.value);
                        // }
                            console.log($this.mails);
                            $this._mailService.onMailsChanged.next($this.mails);
                    }
                }
           // }
    }, 300);
    }
    

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
    openTicket(){
        this.dialogRef = this._matDialog.open(TicketDialogComponent, {
            panelClass: 'tickt-dialog'
        });
    }
    openPolicy(){
        this.dialogRef = this._matDialog.open(PolicyDialogComponent, {
            panelClass: 'policy-dialog'
        });
    }
    openContact(){
        this.dialogRef = this._matDialog.open(ContactDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
    }
    openMembersList(type, mode=false){
        
        this._mailService.createMember=mode;
        this._mailService.oncreateMember.next(mode);
        // this._mailService.isMailDialog=true;
        this._mailService.onDialogChange.next(true);
            this._mailService.user_id='members';
            this._mailService.pagetitle="Members";
            if(type=='shop'){
                this._mailService.isShopList=true;
            }else{
                this._mailService.isShopList=false;
            }
        this.dialogRef = this._matDialog.open(DashboardComponent, {
            panelClass: 'member-list-dialog'
        });
    }
    blockMember(){
        this.dialogRef = this._matDialog.open(BlockMemberDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
    }
    reportMember(){
        this.dialogRef = this._matDialog.open(ReportMemberDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
    }
    composeDialog(): void
    {
        this._mailService.currentTitle='';
        this._mailService.currentContent='';
        this._mailService.isprivate=false;
        this._mailService.RootMessageID=0;
        this._mailService.messageContext='Message';
        this._mailService.popupTitle="Chat Message";
        this.dialogRef = this._matDialog.open(DashboardComposeDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    /**
                     * Send
                     */
                    case 'send':
                        console.log('new Mail', formData.getRawValue());
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        console.log('delete Mail');
                        break;
                }
            });
    }
    privateMessage(): void
    {
        this._mailService.currentTitle='';
        this._mailService.currentContent='';
        this._mailService.RootMessageID=0;
        this._mailService.messageContext='Message';
        this._mailService.isprivate=true;
        this._mailService.multiple=false;
        this.dialogRef = this._matDialog.open(DashboardComposeDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                
            });
    }
    myMessages(pagetype){
        // this._mailService.isMailDialog=true;
        this._mailService.onMailsChanged_private.next(null);
        this._mailService.onDialogChange.next(true);
        this._mailService.loading_dialog_mails.next(true);
        this._mailService.isreport=false;
        this._mailService.searchitems.isreport=false;
        this._mailService.isinvoicelist=false;
        this._mailService.searchitems.isinvoicelist=false;
        this._mailService.user_id=localStorage.getItem('id');
        if(pagetype=='messages'){
        }else if(pagetype=='orders'){
            this._mailService.user_id='myorders';
        }else if(pagetype=='reports'){
            this._mailService.isreport=true;
            this._mailService.searchitems.isreport=true;
        }else if(pagetype=='invoices'){
            this._mailService.isinvoicelist=true;
            this._mailService.searchitems.isinvoicelist=true;
        }
        this.dialogRef = this._matDialog.open(DashboardDialogComponent, {
            panelClass: 'mail-sub-list'
        });
        
    }
    customSearch(type): void
    {
        this.filtered=1;
        this.target=MemberSearchDialogComponent;
        if(type=='datetime'){
            this._mailService.popupTitle="Search";
            this.target=DatetimeSearchDialogComponent;
        }
        this.dialogRef = this._matDialog.open(this.target, {
            panelClass: 'mail-compose-dialog'
        });
    }
    addAMember(type){
        this.openMembersList(type, true);
    }
    suspendAMember(type){
        this._mailService.onsuspendMember.next(true);
        this.openMembersList(type, false);
    }

}
