import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from 'app/services/data.service';
import { environment } from 'environments/environment.prod';

declare var $: any;
declare global {
    interface Window {
        clients: any;
    }
}

@Component({ templateUrl: 'login.component.html',  
styleUrls    : ['login.component.scss'],
encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    // siteurl=environment.siteurl;
    loginForm: FormGroup;
    formData:FormData = new FormData();
    response: any = '';
    content: any = '';
    pageclass: any = '';
    username: any = "";
    password: any = "";
    loginmsg = "";
    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
        , private data: DataService
    ) {
        this.loginForm = this.createComposeForm();

    }

    ngOnInit() {
        if(this.router.url=='/logout'){
            this.logout();
            return false;
        }
        // if(localStorage.getItem('username')){
        //     this.router.navigate(['/dashboard']);
        // }
        // console.log('contactform_data');
        // console.log(localStorage.getItem('contactform_data'));

        // this.route.data.subscribe(data => {
        //     console.log('data here');
        //     console.log(data);
        //     var paramid = this.route.snapshot.params.slug;
        //     var paramid2 = this.route.snapshot.params.slug1;
        //     // var slug='';
        //     // if(paramid=='' || paramid==null || typeof paramid=='undefined'){
        //     //     paramid='home';
        //     // }
        //     // slug=paramid;
        //     // if(paramid2!='' && paramid2!=null && typeof paramid2!='undefined'){
        //     //     slug=paramid+'/'+paramid2
        //     // }
        //     this.pageclass = data.slug;
        //     //console.log(paramid);
        //     this.data.pagecontent(data.slug).subscribe(res => {
        //         this.response = res;
        //         this.response = JSON.parse(this.response);
        //         this.content = this.response.content;
        //     });
        // });


    }
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            UserName     : new FormControl(''),
            password     : new FormControl(''),
            remember     : new FormControl(''),
        });
    }
    login() {
        
        var data={UserName: this.loginForm.value.UserName, password:this.loginForm.value.password};
        // console.log(this.loginForm);
        this.data.login(data).subscribe(res => {
            this.response = res;
            console.log(this.response);
            if(this.response.response==true){
                this.loginmsg='';
                localStorage.setItem('UserName', JSON.stringify(this.loginForm.value.UserName));
                localStorage.setItem('id', this.response.data.UserID);
                localStorage.setItem('CompanyName', this.response.data.Name);
                localStorage.setItem('Role', this.response.data.Role);
                localStorage.setItem('RoleName', this.response.data.RoleName);
                localStorage.setItem('RoleNameClass', this.response.data.RoleNameClass);
                localStorage.setItem('Phone', this.response.data.Phone);
                console.log(this.response.data);
                localStorage.setItem('image', this.response.data.image);
                var sign=this.response.data.SignatureText;
                if(sign=='' || sign==null){
                    sign=this.loginForm.value.UserName;
                }
                localStorage.setItem('SignatureText', sign);
                localStorage.setItem('Sales', this.response.data.Sales);
                // this.router.navigate(['/dashboard']);
                location.href=environment.siteUrl;
            }else{
                this.loginmsg = "Invalid Login";
            }
        });
        // var newPost = ""; // Inputted values
        // // Iterate through the inputs
        // $("input").each(function() {
        //     newPost += $(this).val() + ",";
        // });
        // // Get rid of the last comma
        // newPost = newPost.substr(0, newPost.length - 1);
        // // Store the data
        // localStorage.setItem('contactform_data',JSON.stringify(data));
    }
    logout(){
        this.data.isOnline({UserID:localStorage.getItem('id'), isOnline:0}).subscribe(res => {

        localStorage.removeItem('UserName');
        localStorage.removeItem('id');
        localStorage.removeItem('CompanyName');
        localStorage.removeItem('Sales');
        localStorage.removeItem('Phone');
        // this.router.navigate(['/login']);
        });
        // this.router.navigate(['/login']);
        // location.href=environment.siteUrl+'/login';
    }

    // convenience getter for easy access to form fields
}
