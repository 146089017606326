import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { environment } from 'environments/environment';


@Component({
    selector     : 'ticket',
    templateUrl  : './ticket.component.html',
    styleUrls    : ['./ticket.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TicketDialogComponent
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";

    /**
     * Constructor
     *
     * @param {MatDialogRef<TicketDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<TicketDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createMessage(){
        this._mailService.data.Title=this.composeForm.value.Title;
        this._mailService.data.Content=this.composeForm.value.Content;
        this._mailService.data.type=this._mailService.messageContext;
        this._mailService.data.RootMessageID=this._mailService.RootMessageID;
        this._mailService.data.ForwardedFrom=this._mailService.ForwardedFrom;
        this._mailService.createMessage(this._mailService.data);
    }
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            member_name: new FormControl(''),
            email: new FormControl(''),
            phone: new FormControl(''),
            subject: new FormControl(''),
            issue: new FormControl(''),
        });
    }
submit(){
    var data={
        member_name:this.composeForm.value.member_name,
        email:this.composeForm.value.email,
        phone:this.composeForm.value.phone,
        subject:this.composeForm.value.subject,
        issue:this.composeForm.value.issue
    };
    this.formData.append('member_name', this.composeForm.value.member_name);
    this.formData.append('email', this.composeForm.value.email);
    this.formData.append('subject', this.composeForm.value.subject);
    this.formData.append('phone', this.composeForm.value.phone);
    this.formData.append('issue', this.composeForm.value.issue);
    console.log(this.composeForm.invalid);
    if(this.composeForm.invalid){
        alert('Please fill all fields');
        return false;
    }
    if (confirm('Are you sure?')) {
        alert('Email has been sent! Our support agent will contact you soon.');
        this._mailService.createTicket(this.formData)
                 .subscribe((members: any) => {
                    
         });
      }
    
}
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
    }
}
