<div class="wrapper fadeInDown login-form">

    <div id="formContent">
      
      <div class="dialog-toolbar login-dialog">
            <div class="custom-column column3" style="text-align: left;">
                <span class="site-title">Partz Kwest</span>
            </div>
            <div class="custom-column column3">
                <span class="company-info"></span>
            </div>
            <div class="custom-column column3">
                    <!-- <div class="close-DB" (click)="matDialogRef.close(['delete',composeForm])">
                            <button  mat-icon-button
                            aria-label="Delete"
                            matTooltip="Delete">
                        <mat-icon>close</mat-icon>
                        </button>
                    </div> -->
            </div>
        </div>
      <div>
        <div class="form-logo">
            <img src="assets/images/logos/logo-half.png"/>
        </div>
        <div class="form-data">
            <!-- <h4>PartzKwest Login</h4> -->
            <div class="container">
              <form [formGroup]="loginForm" name="loginForm" class="loginForm">
                <span style="color:red;display: block;margin-bottom: 10px;" *ngIf='loginmsg!=""'>{{loginmsg}}</span>
                <div class="form-group">
                  <label for="exampleInputEmail1">Login</label>
                  <input type="text" class="form-control" formControlName="UserName" placeholder="Enter User Name" required formControlName="UserName">
                  <small class="form-text text-muted error-text" 
                    *ngIf="loginForm.controls.UserName.touched && loginForm.controls.UserName.errors?.required">
                      Please enter User Name
                  </small>
            
                </div>
                
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input formControlName="password" type="password" class="form-control" formControlName="password" placeholder="Password" required>
                  <small class="form-text text-muted error-text"
                  *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">
                  Please enter password
                  </small>
                </div>
                <div class="form-group padding-group">
                    <label for="exampleInputPassword1" style="width: auto;">
                    <input style="width: auto;" formControlName="remember" type="checkbox" class="form-control" >
                      Remember Me</label>
                  </div>
                  <div class="form-group padding-group">
                <button (click)="login()" type="submit" class="save-button" [disabled]="!loginForm.valid"><mat-icon>lock</mat-icon>Submit</button>
                <button type="reset" class="save-button">Reset</button>
                <div class="clearfix"></div>
                  </div>
              </form>
        
            <!-- Remind Passowrd -->
            <!-- <div id="formFooter">
              <a class="underlineHover" href="#">Forgot Password?</a>
            </div> -->
        
          </div>
        </div>
      <!-- Tabs Titles -->

  </div>
  </div>
  </div>
