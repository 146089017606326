<div class="dialog-toolbar">
        <div class="custom-column column3">
            <span class="site-title">Partz Kwest</span>
        </div>
        <div class="custom-column column3">
            <span class="company-info">{{company}} - Policy</span>
        </div>
        <div class="custom-column column3">
                <div class="close-DB" (click)="matDialogRef.close(['delete',composeForm])">
                        <button  mat-icon-button
                        aria-label="Delete"
                        matTooltip="Delete">
                    <mat-icon>close</mat-icon>
                    </button>
                </div>
        </div>
    </div>
<div class="dialog-content-wrapper">

    <!-- <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <h3 style='text-align: center;'>Are you sure you want to {{blocktext}} this memeber?</h3>
       
    </div> -->
    <div class="policy-list">
         <p>
                All invoices between members of the network will be paid within 35 days from the date of the invoice. After 35 days has passed, the member that is owed the money may report the member that owes him. A message will be sent to the owing member giving them “7” working days to pay the invoice or be taken off service until payment is made. Payments sent to Partzkwest from outstanding members will be considered the same as being sent directly to the member owed and will be considered paid you upon receipt. The check will be forwarded at that time to the reporting member. The member who was reported will be removed from report. If a member is out of service already for nonpayment, they will be put back in service upon receipt of payment to partzkwest. If any member has another member taken out of service for false report which they received payment upon, will be charged a$20 ( false report) fee. A 2% fee will be incurred by: yard being reporded of(disputed invoice total)
<br/><br/>
                2. Guarantee of funds (optional)<br/>
                "Partzkwest"offers an option to their members for guarantee of funds for part/parts that were purchased over the network if buyer fails to make payment after being reported up to $2000, and the buyer will be charged a 10% fee. The buyer will be taken out of service until initial amount plus the 10% fee has been made. This option is "not -available at this time,but will be inplemented at a future date.  
                <br/><br/>
                
                3. Rules of conduct<br/>
                
                No messages will be sent over the system that is degrading to another member. No non-business messages will be sent over this system. The only messages that should be sent over the system are for business only. Derogatory messages concerning any other members will result of minimum of 24 hours suspension.
                <br/><br/>
                
                4. Selling a business<br/>
                
                Any member who is selling their business, must advise network at least 30 days before escrow closes so network can advise its members to submit invoices to be paid out of escrow. Network must have escrow number, the escrow company, and the escrow officer name. If the rules are not followed, the “new owner” will be liable for any outstanding invoices.
                <br/><br/>
                5. New member<br/>
                
                When a member sells his business, the new owner will have to go through the same process as the new applicant would have to do, with the section of the installation charge, providing Partsnet that business still active and current. Purchase of the business does not warranty the membership on the network.
                <br/><br/>
                6. Paying network fees<br/>
                
                All network service fees are due and payable on the 1st of each month and are considered late by the 15th. If a payment is not received by the 20th of the month, that member will be taking out of service until payment is received. Any member terminated from "Partzkwest" for nonpayment of their network fees or nonpayment to members,, will have to pay all past-due amounts, and a deposit equal to one month service. 
                <br/><br/>
                7. Disputes<br/>
                
                When there is a dispute between members over at deal, whether it be with the quality of the parts, the price, or damage to the parts and it cannot be taken care of between the countermen or the managers, the matter be will reported to "partzkwest" and will come to some sort of settlement. When one member gives the okay to another member to buy new parts, or in any way change the original deal, it will be the buyers responsibility to have these changes  sent over "Partzkwest"and confirmed by seller. "Partzkwest" ruling, should we need to get involved, will be final and any dispute that arises where these rules have not been followed will result in the claim being allowed." DOCUMENTATION"over the type, is always your best defense as proof.or in"writing"on your invoices.Disputing parties will receive with-in 2 weeks (14 calender days)our "recommendation"Disputing parties have (7) calender day''s to comply or to be de-activated for non-compliance.You will incurr ($100) re-activation fee.IF (CIVIC COURT) find''s in your favor! "Partzkwest" will re-fund your de-activation fee! and re-ward you with one free month of service.
                <br/><br/>
                8. Shipping and receiving parts<br/>
                
                When parts are shipped and arrive at the buyers location damaged, the buyer can accept the shipment and have the driver note the damage on the original invoice, or the buyer can refuse the shipment and have it returned to the sellers. If the damage is not discovered until after the shipment is opened, you will either keep the parts or ship the merchandise back. Under no circumstance, can you deduct the amount of damage from the invoice, without the seller’s written permission. When there is a problem with that shipment, whatever is agreed upon between the parties should be typed over the network to each other so there will be a written record of the agreement.
                <br/><br/>
                9. Returning good parts<br/>
                
                When a part is purchased and the buyer losses to sale or for some other reason does not require the part, you might return the part within 30 days and cancel the order. The seller will be liable for freight both ways. The buyer should send a message to the seller, advising them that the part is coming back and the state the reason why the part is being returned. In this case the buyer is responsible for freight both way and (if the seller wishes) a fee of 10% of the sale price, (the minimum amount shall be no less than $10, the maximum no more than $100) for paperwork and pulling of the parts if the buyer wants to return the parts after 30 days the buyer will need the permission of the seller, if the seller agrees. The buyer will pay the freight both ways and (if the seller wishes) a fee of 20% of the sale price. (The minimum amount shall be no less than $20 and the maximum no more than $200). No part can be returned after 30 days unless authorized by the sellers. Unless there has been written communication between the members prior to the 30 days period.
                <br/><br/>
                10. Returning bad or damage parts<br/>
                
                When a part is ordered and is found that the part is bad, or damaged prior to shipment and cannot be used by the buyer, the buyer should advise the seller of the problem. A defective part or a part that was shipped with damage can be returned by the buyer after notifying the seller of the problem, and the seller must accept the parts back. In this case, the seller will be liable for the freight both ways. The buyer must return the parts to the seller in the same condition as they were received. The buyer cannot do any tear down on parts without the written permission of the seller. The buyer will be liable for any tears down charges made by the shop that parts are sold to, unless agreed to by the seller in writing. In any case, the part that was torn down must be put back together with all the original pieces before sending it back to the seller unless the seller has agreed to the other arrangement in writing.
                <br/><br/>
                11. Returning wrong parts<br/>
                
                When a part is ordered and the seller ships the wrong part to the buyer, the buyer must return the part to the seller and the seller is responsible for the freight both ways. The buyer should contact the seller to see how they want to proceed and not just ship the part back. If there is a problem then report or dispute should be notified.
                <br/><br/>
                12. Receiving parts<br/>
                
                When parts are ordered and shipment is received by the buyer, it is the buyer’s responsibility to make sure all the parts are there. If any parts of the order are missing, the buyer must notify the seller over the network and find out how to proceed. The buyer should also keep a copy of all messages pertaining to the problem from that time on. If the seller does not handle the problem within a reasonable period of time, the buyer may send the parts back and cancel the order. The seller will be liable for freight both ways. The buyer cannot use the parts and deduct the amount for the missing items from his invoice without the written permission of the seller.
                <br/><br/>
                13. Shipment to customer<br/>
                
                When parts are ordered and the buyer wants the parts shipped direct to his customer and the customer refused that shipment, the buyer will be liable for the freight charges both ways and (if the seller wishes) at fee of 10% of the sale price, (the minimum amount shall be no less than $10 and the maximum amount shall be the greater than $100) for paperwork, and pulling off the part. If parts are shipped and the buyer’s customer has no way of unloading the merchandise, the buyer becomes liable for any additional freight charges from that time on. It is the shipper’s responsibility to contact the buyer and advise him of the situation and see what the buyers wants to do from there. . When selling parts that require a core and the buyer wants a "zero" invoice sent to shop, the seller will deliver to the shop, and buyer will be responsible for core.  If the seller is dealing directly with the shop, it is the seller responsibility to get the core returned. The seller should either get paid for the core upon delivery or pick the core of at the time of delivery. If the core charge is paid at the time of delivery the seller will be required to refund the shop the core charge when the seller picks up the core.
                <br/><br/>
                14. Authorized price changes<br/>
                
                When there is a problem with the quality of the parts sent and the seller gives the okay to deduct money from the invoice, or if the seller I agrees to deduct for parts or says it is okay to buy parts to replace damaged ones, it is the buyers responsibility to have the sellers send the authorization over the type. This way it will be available should a problem arise. Under no circumstances will the buyer replace damaged parts and deduct the amount from the sellers invoice without written permission. If the buyer does not have this authorization in writing, he will have no claim.
                <br/><br/>
                15. Inspecting parts<br/>
                
                When parts are purchased and the seller has authorized the parts to be taken apart for inspection and the parts are found to be defective, the buyer must notify the seller what parts are defective and what it will take to fix them. The seller may then decide to authorize the purchase of the parts to repair, replace with another part, or have his parts returned. The seller will be liable for freight from that point on. If the seller authorizes replacement of any parts the buyer should get this okay in writing over the network. Under no circumstance may the buyer repair the part and deduct the cost from the original bill without the written permission of the seller. Also, if the buyer guarantees labor to his customers, he cannot deduct the labor charge from the sellers invoice unless the seller has guaranteed labor in writing on the invoice or over the network. The seller will not be liable for the cost of the tear down unless they agree to it in writing prior to the tear down being done. Any part taken apart by the buyer or the buyers customer must be put back together with all of the pieces there before being returned to the seller. The buyer will be liable for the cost of the part if this is not done, unless the seller has authorized it in writing.
                <br/><br/>
                16. Marking parts<br/>
                
                When shipping parts such as engines and transmissions. You must clearly mark the parts with a mark that cannot be removed or write the ID number from the part on the invoice. All parts should be marked clearly and the buyer should be advised where and how it is marked. If the buyer receives a part that is supposed to be marked and is not, the buyer should notify the seller “in writing” over the type . If there is an ID number on the part being sold, write it on the invoice!
                <br/><br/>
                17. Cores<br/>
                
                If there is a core charge due, it must be in written on the original invoice “core due” with the amount, or a core invoice must be issued showing the amount due. It is the buyer responsibility to return the core . If the core is to come back with many items, they must be itemized. We consider a core on a COMPLETE ENGINE to be a long block engine with the intake, carburetor and distributor. If your core does not say complete engine, you must list  those items ordered . The (core charge) or return of the core is a  responsibility of the yard who is collecting the money from the customer. the core must be returned within 45 days.Direct deliverie''s to (shops) are at rhe discretion of the seller!cores not returned with-in (45) days can be submitted to "report" any suggestions or feedback is more than welcome!
                <br/><br/>
                18. Commissions<br/>
                
                When a commission is billed, the yard paying the commission cannot deduct from the commission without the billing yards authorization in writing. If the paying yard does not have this authorization in writing the original commission is payable. You cannot deduct from the commission for failure to get a core returned, or for deductions you had to make on to sale. These are not under the control of the yard billing the commission and is not their responsibility. If the yard that the deal was turned to cannot supply the part, they must notify the yard that turned the deal to them. It is the original yards decision how to proceed. If the yard the deal was turned to have to obtain the parts somewhere else, without the original yard giving the okay in writing, they will be liable for the origin of commission.
                <br/><br/>
                19. Lawsuits<br/>
                
                If there is a dispute between members and that is no clear-cut answers, such AS who is or not correct about a certain situation and "Partzkwest" is unable to reach a clear decision as who is at fault, the member who feels he has been wronged may bring as “small claim “lawsuit against the other member and if that judgment is received "Partzkwest"will treat the judgment as nonpayment problem. The member who has ordered to pay will be required to do so by that date set by court or they will be removed from the network until such time as payment is made.                                        <br/><br/>                                                                                                                                                                                         
                
                20. RELEASE OF LIABILITY HOLD HARMLESS INDEMNITY CLAUSE: <br/>
                The Indemnitor/Member will hold harmless and indemnify the Indemnitee/PARTZKWEST, against any and all claims and actions arising out of partication of the Indemnitor in the Activity/Membership, including without limitations expenses, judgments fines, settlements, as any other cost incurred in connection with any liability, suit action, loss or damage arising or resulting from the indemnitors partication in the membership activity with PARTZKWEST/CONNECT ALL. In the event of any claims or action PARTZKWEST/CONNECT ALL requires written notice and all available information related to the claim or action (10) business days of the commencement of any legal proceeding relating to the claim or action. The Indmnitor agrees to cooperate in good faith and use best efforts to ensure that PARTZKWEST/CONNECT ALL, is indemnified and reimbursed for any and all Expenses, judgement, Fines, Settlement, and other amount actually and reasonably incurred in connection with the Defense of any claims or action resulting from particiation in membership.
                This holds harmless Indemnity clause is broad and inclusive as permitted by law. The Indemnitor/Member affirms legal age, and fully understands this clause. PARTZKWEST/CONNECT ALL is a Communication Service and does not inspect parts. PARTZKWEST/ CONNECT ALL urges the member to inspect all parts and warranty''s terms conditions before you buy.', 1, 1, CAST(N'2014-06-25T06:30:35.373' AS DateTime), 1, CAST(N'2018-05-20T05:32:18.760' AS DateTime))
                INSERT [dbo].[Articles] ([ArticlesID], [Title], [Content], [Type], [CreatedBy], [DateCreated], [ModifiedBy], [DateLastModified]) VALUES (2, N'', N'Terms of Service
                <br/><br/>
                PartsNET Software User Agreement<br/>
                1. Our Service<br/>
                PartsNET Software ("PartsNET Software") provides vehicle repairers and vehicle part suppliers a platform to buy and sell new and used parts. Using the PartsNET Software web site, repairers can direct order from preferred suppliers or request quotes for a particular part or parts. The request is available to selected suppliers that are invited to participate in the PartsNET Software site. The suppliers, in turn, submit quotes to the repairer. The repairer may receive one or more quotes from many suppliers. The repairer selects one or more suppliers and orders the requested parts. Suppliers confirm their acceptance of the order to repairers. The repairer may communicate through the site with the supplier, to request clarification or negotiate adjustments to the quote. The suppliers ship the parts to the repairer and the repairer pays the suppliers. Finally the parties submit feedback to PartsNET Software about their experience.<br/>
                PartsNET Software supplies the electronic platform for these transactions as well as transmission of requests via facsimile or other means. It does not take part in the purchase or sale of any of the parts. It does not facilitate the delivery of payments to suppliers. PartsNET Software facilitates the interaction of repairers and suppliers but does not act as an agent for either. PartsNET Software may charge a fee to the suppliers for use of the PartsNET Software Application.  PartsNET Software will not charge a fee to the repairers for use of the PartsNET Software Application.<br/><br/>
                2. This User Agreement<br/>
                This User Agreement (this "Agreement") describes the rights and obligations of PartsNET Software and each user, including the entity that is registered (the "User") to use the PartsNET Software Application. The term "User" includes any person or entity that enters into a transaction with a Repairer or Supplier using the information supplied by PartsNET Software or the PartsNET Software Application, such as a Supplier receiving a Request from PartsNET Software via facsimile or email, but not through the Site. "Site" as used in this Agreement means the website owned and operated by PartsNET Software, at http://www.PartsNETSoftware.com and http://my.PartsNETSoftware.com, and all websites and Site pages owned and operated by PartsNET Software which are linked to, or accessible from, such URLs. "User" shall include any of User''s officers, employees, agents, contractors, or other persons acting on behalf of the User, under the direction of the User, or using the equipment or facilities of the User. The User shall be responsible for, and subject to any obligation, any liability, or shall provide indemnification obligations for the actions, of any person that is (a) an officer, employee agent, contractor, or other person acting on behalf of the User, (b) acting under the direction of the User, or (c) using the equipment or facilities of the User.<br/>
                This User Agreement incorporates the following documents and pages by reference:<br/>
                i. PartsNET Software''s Privacy Policy (the "Privacy Policy");<br/>
                ii. PartsNET Software''s training materials;<br/>
                iii. Participant Application;<br/>
                iv. the terms provided on documents delivered by PartsNET Software via facsimile to a Supplier;
                v. any terms, conditions, policies, or instructions found on the Site; and
                vi. such terms otherwise referenced in this Agreement.<br/>
                References to this "Agreement", unless specifically provided otherwise, incorporate each of the items listed above. If any provision of this Agreement conflicts with any of the items (i) through (vi) above, the terms of this Agreement shall control.<br/><br/>
                3. Acceptance of Agreement<br/>
                Use of the Site, use of information from a facsimile or email from PartsNET Software to negotiate a Product transaction with a Repairer or Supplier, or any other use of the PartsNET Software Application, shall be deemed agreement to all of the terms and conditions contained in this Agreement. If a Supplier sells Products based on information from a facsimile from PartsNET Software, then such Supplier shall be deemed to have accepted the terms of this Agreement as if such Supplier were a "User". As to each User, this Agreement specifically supersedes any other license or agreement between PartsNET Software and User, including PartsNET Software''s Software License Agreement for Estimatic Module. The User need not execute a formal written agreement, but is bound by this through the use of the PartsNET Software Application. Certain Users may be a Supplier that (A) have not registered as a Participant for use of the Site, or (B) registered as a Participant, but indicated that it should only receive requests via facsimile. In such instances, those provisions of this Agreement that relate solely to the use of the Site shall not apply to such Suppliers. However, all other provisions of this Agreement shall apply to such Suppliers.<br/><br/>
                4. PartsNET Software Authority to Modify Agreement<br/>
                PartsNET Software may amend supplement, or substitute any term, provision or condition in this Agreement, in whole or in part, at any time.  PartsNET Software will notify User of modifications the next time they access the system and User will be prompted to accept such modifications.  Any modifications shall be effective immediately upon acceptance by User. Each User is responsible for understanding the latest version of this Agreement. If User does not wish to be bound by the modified Agreement, then User shall decline the changes, discontinue use of the Web Site and notify PartsNET Software of its intent to terminate use. User''s continued use of the Site shall constitute acceptance by User of the Agreement, as amended.<br/><br/>
                5. Other Defined Terms<br/>
                "Change of Control" means the transfer of more than 50% of any class of voting equity, the sale of substantially all of the assets, or other merger or reorganization where holders of 50% of the voting equity before such transaction do not have 50% or more of the voting equity of the surviving entity.<br/>
                "Losses" means claims, damages, expenses, suits, actions, liabilities, losses, costs (including reasonable attorneys'' fees), fines, penalties, taxes or damages (collectively, "Losses")<br/>
                "Participant" means any User that registers as a participant of the PartsNET Software Application pursuant this Agreement.<br/>
                "Order" means an order, or offer to buy, a Product, made by a Repairer to the Supplier.
                "PartsNET Software Software" means any software (including object code and source code) and documentation provided by PartsNET Software to User, whether by download, on disk, in read-only memory, hard copy, or on any other media or in any other form, including any updates or upgrades that PartsNET Software elects to provide to User, at its sole discretion and subject to terms imposed by PartsNET Software.<br/>
                "PartsNET Software Application" means the PartsNET Software Software, the Site, the delivery by PartsNET Software of Requests, Quotes or Orders by facsimile, email or any other medium, and any other service or provided by or on behalf of PartsNET Software in the facilitation of the purchase of Products where one or more of the parties to the transaction use any portion of the PartsNET Software Software or the Site.<br/>
                "Product" means a product, part, item, or other tangible object that is the subject of a Request, including any vehicle Products.<br/>
                "Quote" means a response by a Supplier to a Request from a Repairer.<br/>
                "Repairer" means a person or entity in the business of repairing vehicles.<br/>
                "Request" means a request by a Repairer for quote for prices, terms and part characteristics from a Supplier.<br/>
                "Supplier" means a person or entity that is a supplier of vehicle Products.<br/><br/>
                6. Participation<br/>
                A. Participation Required to Use Site and License Intellectual Property
                The Site may only be used by registered Users. PartsNET Software may invite new Users to participate from time to time in its sole discretion.<br/>
                B. Becoming a Participant<br/>
                A prospective User may register as a User by completing the Participant Registration Application on the Site or submitting a completed Participant Registration Application to a PartsNET Software representative. PartsNET Software may accept or reject any application for User participation in its sole discretion.<br/>
                C. Representations by User<br/>
                As a condition to User''s participation, each User represents and warrants to PartsNET Software that:<br/>
                i. the information provided on the Participant Registration Application, or any other information provided to PartsNET Software by User, is true, complete and accurate;<br/>
                ii. should User be granted participation, User shall update information from User immediately after becoming outdated or inaccurate; all Participant information shall remain true, complete and accurate at all times;<br/>
                iii. the person submitting the application for participation has all the requisite authority to act for the User; User shall immediately notify PartsNET Software if such person ever ceases to have such authority;<br/>
                iv. User has not relied upon any pre-contractual advice, representations or warranties given or made by PartsNET Software or any other party in agreeing to be bound by this Agreement; and<br/>
                v. PartsNET Software may contact User for any purpose.<br/>
                D. Termination of Participation<br/>
                User may terminate its participation upon written notification to PartsNET Software, which shall be effective on the earlier of (i) acceptance by PartsNET Software, or (ii) 30 days after delivery of such notice to PartsNET Software. PartsNET Software may terminate the participation of any User, or refuse User access to the Site, at any time, without notice, at PartsNET Software''s sole discretion, for any reason or no reason. Termination of User''s participation shall result in the deactivation of User''s participation and User shall not access the Site. User shall have no right to any information or files relating to User or its participation. Termination of a User''s participation shall immediately terminate the license to the PartsNET Software Software, any PartsNET Software intellectual property and other information. Sections 7(License by User), 23 (Remedies for Breach by Participants), 25 (Security), 26 (Disclaimer of Warranties), 27 (Limitation of Liability), 28 (Indemnification), and 29 (General Provisions) shall survive the termination of this Agreement.<br/><br/>
                7. License by PartsNET Software; Terms of Use<br/>
                A. License. The PartsNET Software Software is licensed, not sold, to User by PartsNET Software for use only under the terms of this Agreement. Subject to User''s compliance with all the terms of this Agreement, PartsNET Software grants to User a single, limited, non-exclusive, personal, non-sub licensable, non-assignable, non-transferable, revocable, restricted license to use the Site and the PartsNET Software Software (in object code only) on a single computer in accordance with this Agreement. PartsNET Software reserves all rights in the Site and the PartsNET Software Software not expressly granted to User. The rights granted in this Agreement are limited to PartsNET Software''s and its licensors'' intellectual property rights in the PartsNET Software Software and do not include any other patents or intellectual property rights.<br/>
                B. Restrictions on license and use.<br/>
                The User shall not:<br/>
                i. Resell, rent, lease, lend, sublicense or otherwise transfer the PartsNET Software Software or User''s license rights to the Site or the PartsNET Software Software.<br/>
                ii. THE PARTSNET SOFTWARE SOFTWARE IS NOT INTENDED FOR USE TO OPERATE EQUIPMENT IN WHICH THE FAILURE OF THE PARTSNET SOFTWARE SOFTWARE COULD LEAD TO DEATH, PERSONAL INJURY, OR PHYSICAL OR ENVIRONMENTAL DAMAGE.<br/>
                iii. Copy, disassemble, modify, decompile, adapt, reproduce, store, distribute, print, commercialize, reverse engineer, disassemble, modify, or create derivative works of the PartsNET Software Software, any information on the Site (other than pursuant to this Agreement) or any part thereof.<br/>
                iv. Contact any User for the purchase of vehicle parts once those parts have been included in a Request on PartsNET Software, other than as provided under this Agreement.<br/>
                v. Use the Site other than to procure or supply Products used to repair motor vehicles.<br/>
                vi. Share data, screen shots or reporting from the PartsNET Software Application outside User’s Company or other than for purposes of managing their own parts procurement or related business functions.<br/>
                C. Termination of license. PartsNET Software may terminate any license granted under this Agreement at any time. The rights granted in this Agreement (i) are limited to PartsNET Software''s and its licensors’ intellectual property rights in the site and (ii) do not include any other patents or intellectual property rights. The information and content on the Site may only be viewed or downloaded by User to perform its obligations under this Agreement and use the services as described in this Agreement.<br/><br/>
                8. License by User<br/>
                The User hereby grants a license to any information, data, software, photographs, graphs, videos, typefaces, graphics, music, sounds, and other material sent, transmitted, or uploaded by User on the Site (collectively, "User Content"). Such license shall be to PartsNET Software, its affiliates, and their respective contractors and business partners. The license shall be non-exclusive, transferable, sub-licensable, royalty-free, fully paid-up, and worldwide. The license shall permit PartsNET Software, only to the extent necessary to perform its obligations or exercise its rights expressly granted in this Agreement, (i) to use, copy, publicly perform, digitally perform, publicly display, and distribute User Content, and to (ii) to prepare derivative works based on, or incorporate into other works, User Content, with or without attribution, such as the aggregation of data regarding users, transactions, products or user ratings.<br/><br/>
                9. Fees for using the Site and the PartsNET Software Software<br/>
                A. Fees. PartsNET Software shall charge such fees as are provided in the Site. PartsNET Software shall provide at least 60 calendar days'' notice to Users before increasing fees.<br/>
                B. Payment of Site Fees. Payment to PartsNET Software for the license to use the PartsNET Software Software, the license to use the Site, and any other services performed by PartsNET Software pursuant to this Agreement, are as provided in the Site.<br/><br/>
                10. Use of the Site – Basic Service – Requests, Quotes and Orders<br/>
                A. Users provide basic information regarding their firms and the nature of the Products that they may purchase or supply. PartsNET Software may supplement the descriptions with information obtained from third parties regarding the User.<br/>
                B. Repairers select a set of preferred Suppliers from whom they typically order Products. Repairers shall select such Suppliers from an extensive list provided on the Site, and may recommend additional Suppliers to be invited to participate.<br/>
                C. Suppliers shall indicate if they choose to only receive Requests via facsimile or other means not through the Site, and therefore shall not submit Quotes ("Fax-Only Suppliers").<br/>
                D. A Repairer requests Quotes for one or more Products by posting a Request on the Site. The Request shall include information on the vehicle and Product details. The Request shall specify a "Request Closing Time", which is the deadline by which Suppliers shall provide Quotes.<br/>
                E. Suppliers may respond to a posted Request from a Repairer.<br/>
                F. If a Supplier responds to a Request, it shall submit a confidential Quote, which includes Product and delivery information as provided below.<br/>
                G. A Quote from a Supplier shall be delivered to the Repairer immediately after the Request Closing Time.
                H. No Quotes shall be allowed after the later of i) the Request Closing Time or ii) the moment the Repairer views the submitted Quotes.  The Repairer or PartsNET Software may extend the Request Closing Time only if the Repairer has not begun to view the submitted Quotes.<br/>
                I. Repairer shall select a single Quote or reject all of the Quotes, for each Product. If the Repairer requires additional Quotes after the Request Closing Time, it may resend the Request and may include additional Suppliers in the new Request with a new Request Closing Time. The Repairer may withdraw the Request and not purchase the Product requested through the quoting procedures on the Site. PartsNET Software will facilitate the Repairer providing a notice to all suppliers who provided Quotes on that request which shall state the reasons for the withdrawal.
                J. Repairers and Suppliers may communicate via the Site or via other means, such as facsimile, email or telephone. Such communications may include any permissible communications under this Agreement regarding the Product, Request, Quote, or Order.<br/>
                K. If the Repairer selects an entire Quote or individual Products from a Quote, PartsNET Software shall notify each other Supplier that provided an entire Quote or some individual Products on a Quote that it was not accepted by the Repairer.<br/>
                L. After selecting a Quote, Repairer shall send an Order via the Site to the Supplier for the Products to be purchased.
                M. The Repairer may configure the Site to require a review by the Supplier of an Order submitted by the Repairer that does not conform with the Quote received from the Supplier. The Supplier may accept these Orders, or revise the Order for confirmation by the Repairer. The Repairer may accept, reject or further modify the revised Order from the Supplier. The Repairers and Suppliers may undertake as many rounds of revisions as they may agree.
                N. The Supplier confirms its acceptance of the final agreed Order and its commitment to deliver the Product on the terms agreed.<br/>
                O. The Products shall be delivered pursuant to the terms of the final agreed Order.<br/>
                P. The Repairer and Supplier shall each provide feedback on the Site as to their experience with the other in connection with a completed purchase of Products using the Site.<br/>
                Q. A Repairer may place an Order directly with a Supplier using the Site without submitting a Request to one or more Suppliers. Such transaction shall omit the process of submission of Quotes by Suppliers.<br/>
                R.  Repairers and Suppliers may vary terms of the Orders, Requests or Quotes as mutually agreed.<br/>
                S. PartsNET Software may use feedback provided by other Users to rate or provide editorial comment on a User.<br/><br/>
                11. Use of the Site – Direct Orders<br/>
                A. Delivery of Orders. Repairers may submit an Order directly to an entity or individual Supplier via the Site. Those Suppliers that are not users on the Site, or that indicated that they would receive "Fax-Only" communications, shall receive a facsimile Order from the Repairer as relayed by PartsNET Software.<br/>
                B. Delivery of Products. The Supplier shall deliver the Products ordered to the Repairer. All terms of this Agreement relating to Quotes shall not apply to such direct Orders. All other terms of this Agreement shall apply to such direct Orders.<br/><br/>
                12. Requests<br/>
                A. Regarding Requests, a Repairer shall:<br/>
                i. Submit Requests with sufficient information to allow Supplier to submit accurate Quotes. Requests shall contain sufficient information to allow Suppliers to deliver Products and complete the transaction.<br/>
                ii. Not purchase the Products other than through the Site after the Repairer has submitted a Request, until the later of (i) the Request Closing Time, or (ii) Repairers withdrawing the Request.<br/>
                iii. Post Requests for Products that Repairer intends, in good faith, to purchase.<br/>
                iv. Provide a reason, via the Site, for any Request withdrawn by the Repairer.<br/>
                v. Remove or modify any Request if PartsNET Software determines in its sole discretion that the Request violates any provision of this Agreement.<br/>
                B. A Repairer may modify a Request before the Request Closing Time.<br/><br/>
                13. Quotes<br/>
                A. Repairer’s Rights and Obligations - Quotes<br/>
                i. Repairer may review Quotes only after the Request Closing Time.<br/>
                ii. Repairer may select any Quote, any combination of Quotes, or reject all Quotes. Repairer shall select a Quote if one or more Quotes substantially conform to the Request. Repairer shall use best efforts to place Orders for at least 60% of its Requests.<br/>
                iii. Repairer need not explain to Supplier why the Repairer did not accept a Supplier''s Quote.<br/>
                iv. Repairer may accept or not accept a Quote based upon any reasonable material criteria.<br/>
                v. If the Repairer rejects all Quotes responding to a particular Request, then Repairer may request additional Quotes after the Request Closing Time, or withdraw the Request before or after the Request Closing Time.<br/>
                vi. Repairers shall not accept Quotes submitted after the Request Closing Time.<br/>
                B. Suppliers'' Rights - Quotes<br/>
                Supplier may (a) submit a Quote in response to a Request, and (b) review and confirm an Order, but only if Supplier can provide Products on the terms and conditions specified in such Quote.
                C. Suppliers'' Obligations - Quotes<br/>
                Supplier shall:<br/><br/>
                1. Make best efforts to provide Quotes to those Repairers that have identified it as a preferred Supplier.<br/>
                ii. request additional information from the Repairer if the Supplier requires such additional information to reasonably respond to a Request;<br/>
                iii. submit Quotes to the Repairer before the Request Closing Time;<br/>
                iv. not amend or withdraw Quotes after the Request Closing Time, unless Products have become unavailable or it otherwise becomes impossible for Supplier to fulfill an Order (in which case, Supplier shall withdraw its Quote as soon as it becomes evident that it cannot fulfill the Order);<br/>
                v. provide Quotes for Products that substantially conform to all terms of the Request;<br/>
                vi. honor the payment terms selected by Repairer if multiple payment terms are offered.<br/>
                vii. provide Quotes with the following information:<br/><br/>
                1. Prices for Products in U.S. dollars.<br/><br/>
                2. A competitive price for the requested Products, which shall be no higher than the price that would be offered to the Repairer or an equivalent trade buyer outside of the PartsNET Software Application, net of all discounts and rebates.<br/><br/>
                3. As available to the Supplier, descriptions of all material Product defects.<br/><br/>
                4. Description of any failure of the Products to be of merchantable quality or fitness for their intended purpose.
                5. Descriptions of required Product refurbishment, together with refurbished Product allowances and estimated repair times.<br/><br/>
                6. Listing of freight costs shall be included in the Product prices and not listed separately.<br/><br/>
                7. The following terms, which shall be noted conspicuously in comments section provided by the Site regarding a Quote: (a) non-standard warranty conditions; (b) restocking fees exceeding ten percent (10%) for returns; (c) Products that Repairers may not return; (c) payment terms.<br/><br/>
                8. Amount of allowance or credit to refurbish a Product so that it becomes merchantable. Such credit against the price shall account for refurbishing time and materials as agreed to by the Repairer and Seller.<br/><br/>
                14. Orders<br/><br/>
                A. Obligations Regarding Orders – Repairers<br/>
                Repairers shall:<br/><br/>
                i. make best efforts to place Orders via the Site when Suppliers have provided conforming Quotes;<br/>
                ii. not submit Orders for Products that such Repairer cannot purchase for financial, legal or other reason;<br/>
                iii. submit an Order as soon as reasonably practical after selecting a Quote. Rights Regarding Orders – Repairers
                Repairers may reject Confirmed Orders that do not provide for Products conforming with placed Orders in all material respects.<br/>
                C. Obligations Regarding Orders – Suppliers<br/>
                Supplier shall:<br/>
                i. confirm any Order received (a) during normal business hours within one (1) hour of receipt, or (b) if received outside normal business hours by 9 a.m. (Supplier time) the following business day;<br/>
                ii. provide Products ordered that reasonably conform to the terms of the related final and agreed Quote.<br/>
                15. Purchase and Sale of Products<br/><br/>
                Unless Repairer and Supplier have entered into their own terms of trade, then these default terms contained in Section 15, 16 and 17 will apply.<br/>
                A. Delivery.<br/>
                i. Supplier shall supply the Products in accordance with the final agreed Order, including compliance with delivery times.<br/>
                ii. Supplier shall hold title and bear risk of loss in Products until delivered to and signed for by Repairer, at which time title and risk of loss shall pass to the Repairer.<br/>
                B. Product Quality. Products shall be of good and merchantable quality, except to the extent a final agreed Quote specifies required refurbishing of the Products. Products shall materially conform to the specifications set forth in the final agreed Quote.<br/>
                C. Payment. Repairer shall pay for Products by the due date specified in the Order or invoice, except to the extent of disputes raised promptly by Repairer with the Supplier.<br/>
                E. Supplier shall warrant all used Products supplied for a period of not less than 30 days from date of invoice. All new Products shall be covered by manufacturers'' standard warranty.<br/><br/>
                16. Refurbished Products<br/>
                Unless Repairer and Supplier have entered into their own terms of trade, then these default terms contained in Section 16 will apply.<br/>
                A. The Supplier may provide for allowances or credits against the Order price for Products that need refurbishing.<br/>
                B. Allowance or credit terms regarding refurbished Products shall be as agreed between Repairer and Supplier.<br/>
                C. Repairer may return a Product if Repairer reasonably determines that the refurbishment credit or allowance does not cover Product refurbishing costs.<br/><br/>
                17. Products Returns<br/>
                Unless Repairer and Supplier have entered into their own terms of trade, then these default terms contained in Section 17 will apply.<br/><br/>
                A. Repairer may return a Product if the quality or specifications materially deviate from the Product Quote.<br/>
                B. Supplier may credit the Repairer the full value of the returned Product and pay the cost of freight to return the Product.<br/>
                C. Repairer shall promptly advise Supplier regarding any problem with delivered Products. Repairer shall return Products warranting a credit from Supplier under this Agreement, within 14 days of delivery.<br/>
                D. Supplier shall accept all Products returned by Repairer and, within 30 days of the return, credit Repairer the full invoiced payment. Repairer shall pay delivery costs for the return to the Supplier if (i) the non-conforming Product is the result of inaccurate Orders that are the fault of the Repairer, or (ii) the Product conforms to the Quote and the Supplier''s performance complied with the Quote in all material respects. The Supplier shall bear the cost for Product returns if Supplier delivered a Product that materially deviated form that specified in the Quote or the Supplier otherwise breached its duties under this Agreement in connection with delivery of the Product.<br/>
                E. Supplier shall not assess a restocking fee for returns of products that are (i) defective or of poor quality, (ii) delivered late, or (iii) not the Products specified in the final agreed Quote in all material respects. Supplier may not assess a restocking fee for Repairer returns of electronic sensor components and related safety systems that are (1) new, and (2) do not materially conform to the Quote, or are delivered late. Supplier may assess a restocking fee for returns of electronic sensor components and related safety systems that are (1) used, and (2) materially conform to the Quote, including timely delivery.<br/><br/>
                18. User Technical Specifications and Obligations<br/>
                A. User shall only use hardware and software that satisfy standards provided in the Site and that (i) permit complete performance of all Site functions and displays, (ii) operate the PartsNET Software Software, (iii) transmit Requests, Quotes and Orders, and (iv) permit the performance by all parties of obligations under this Agreement.<br/>
                B. User shall not:<br/>
                i. access unauthorized or restricted portions of the PartsNET Software Application, or PartsNET Software''s proprietary or confidential information and data;<br/>
                ii. damage or harm the Site.<br/>
                iii. upload, mail, e-mail, or otherwise transmit (i) any unsolicited or unauthorized advertising or promotional material, (ii) information from the Site or PartsNET Software other than as required to deliver a Request, Quote, or Order, (iii) illegal or illicit code, including viruses, Trojan Horses, anomalies, self-destruction mechanisms, copy protection schemes, or any other computer instructions that interfere with the Site or PartsNET Software Software.
                19. No Manipulation of the Market<br/><br/>
                A. User shall not:<br/>
                i. collude with other Repairers or Suppliers to manipulate Product prices;<br/>
                ii. discuss or share information gathered through the PartsNET Software Application with other Users or third parties other than the insurance carrier listed on the Request;<br/>
                iii. disclose a particular Quote or Request, or the existence of the Quote or Request, to any third party, including any other Repairer or Supplier (other than the party supplying the Quote);<br/>
                iv. manipulate the Site or the PartsNET Software Application that would conceal information from other Users or interfere with an open exchange of information and delivery of Products based on the fairest set of price and terms; or
                v. post Requests, Quotes and submit Orders that are in any manner (i) inaccurate or incomplete, or(ii) defamatory, offensive, or in violation of any rule or law.<br/>
                B. Repairer shall not:<br/>
                i. notify a Supplier in advance of other Suppliers receiving a Request;<br/>
                ii. share information not publically available in the Request with a Supplier that is not available to other Suppliers that receive the request;<br/>
                iii. provide incomplete, inaccurate or misleading information in a Request;<br/>
                iv. share quoted pricing of a Supplier with its competitors; or<br/>
                v. disclose confidential login credentials to a Supplier or any other party.<br/><br/>
                20. Good Faith Promotion of Arms-Length Transactions<br/><br/>
                A. User shall:<br/>
                i. notify PartsNET Software of any unethical, unprofessional, or illegal conduct it encounters from other Users;
                ii. act in good faith and fair dealing in using the Site and performing User''s obligations, including (A) Requests, Quotes, and Orders, (B) approvals and rejections of Quotes by a Repairer, (C) the purchase and sale of Products, (D) any feedback provided to PartsNET Software, and (E) dealing with all other Users and PartsNET Software.
                B. User shall not:<br/>
                i. discourage any Supplier from submitting a Quote in response to a Request;<br/>
                ii. establish an unfair competitive disadvantage among other Users;<br/>
                iii. circumvent the intended purpose of the Site or the PartsNET Software Application; or<br/>
                iv. collude with, or encourage, any party to breach this Agreement.<br/>
                C. Repairer shall not:<br/>
                i. provide any unfair advantage to any Supplier or otherwise manipulate the market, such as set an unreasonably short Request Closing Time period;<br/>
                ii. withdraw a Request following the receipt of conforming Quotes and purchase the requested Products outside the PartsNET Software Application without using a substantial portion of the functions of the PartsNET Software Application; or<br/>
                iii. request price discounts from prices provided in Quotes to obtain lower prices outside of the PartsNET Software Application;<br/>
         </p>
    </div>
    <!-- <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="blockMembers()"
                    class="save-button"
                    [disabled]="composeForm.invalid"
                    aria-label="SAVE">
                {{blocktext}}
            </button>
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div> -->
</div>
