<div class="dialog-toolbar">
        <div class="custom-column column3">
            <span class="site-title">Partz Kwest</span>
        </div>
        <div class="custom-column column3">
            <!-- <span class="company-info">{{company}} - Block a member</span> -->
        </div>
        <div class="custom-column column3">
                <div class="close-DB" (click)="matDialogRef.close(['delete',composeForm])">
                        <button  mat-icon-button
                        aria-label="Delete"
                        matTooltip="Delete">
                    <mat-icon>close</mat-icon>
                    </button>
                </div>
        </div>
    </div>
<div class="dialog-content-wrapper">

    <!-- <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <h3 style='text-align: center;'>Are you sure you want to {{blocktext}} this memeber?</h3>
       
    </div> -->
    <div class="blockmember-list">
            <div class="search-box">
                    <img src="assets/images/icons/search-icon.png"/>
                    <input type="text" name="member-list" (input)="search($event)"/>
                </div>
            <ul>
                            <li *ngFor="let member of memberslist" >
                                    <spam class="membername"> {{member.Name}}</spam>
                                     <span class="blockstatus" (click)="blockMembers($event, member.UserID, member.BlockedUserID!='' && member.BlockedUserID!=null?'unblock':'block')">{{member.BlockedUserID!='' && member.BlockedUserID!=null?'unblock':'block'}}</span> 
                            </li>
                    </ul>
    </div>
    <!-- <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="blockMembers()"
                    class="save-button"
                    [disabled]="composeForm.invalid"
                    aria-label="SAVE">
                {{blocktext}}
            </button>
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div> -->
</div>
