<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{company}} - {{pagetitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <form name="composeForm" [formGroup]="composeForm" class="compose-form" fxLayout="column" fxFlex>
                <mat-form-field *ngIf='isforward || ismemberReply || isprivate' appearance="outline">
                    
                        <mat-label>Select Member</mat-label>
                                <mat-select multiple='{{multiple}}' #singleSelect
                                formControlName="members">
                                <mat-option>
                                        <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Find Member" ></ngx-mat-select-search>
                                      </mat-option>
                                <mat-option *ngFor="let member of filteredlist"  [value]='member.UserID'>
                                        {{member.Name}}{{member.UserName!=null? ' - ':''}}{{member.UserName!=null?member.UserName:''}}
                                </mat-option>
                              </mat-select>
                                <mat-error>Select at least 1 memeber!</mat-error>
                    </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Make/Model</mat-label>
                <input matInput name="Title"
                       formControlName="Title" required>
                       <!-- <div ng-show="composeForm.Title.$error.required">Tell us your name.</div> -->
            </mat-form-field>
            <div class="attachment-list">
  
                    <div *ngIf="fileslist.length>0">
                    <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let file of fileslist">
                        <div>
                            <span class="filename">{{file.name}}</span>
                            <!-- <span class="size ml-4">(12 Kb)</span> -->
                        </div>
    
                        <button mat-icon-button aria-label="Delete attachment" (click)='deletefile()'>
                            <mat-icon class="s-16">close</mat-icon>
                        </button>
                    </div>
                    </div>
                    <div *ngIf="fileslist.length==0">
                        <div class="select-file">
                            <label for="input-file-id" class="file-label">Picture</label>
                            <div class="filebtn">
                                    <input (change)="fileChange($event)" style="display:none" id="input-file-id" type="file" accept=".jpg,.jpeg,.png"/>
                                    <label for="input-file-id" class="md-button md-raised md-primary"><mat-icon style="    margin-top: 13px;">attach_file</mat-icon></label>
                            </div>
                                
                        </div>
                    </div>
                </div>
            <mat-form-field appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput name="Content"
                          formControlName="Content"
                          rows="6">
                </textarea>
            </mat-form-field>

            
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button class='partzbutton'  mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
            aria-label="Delete"
            matTooltip="Delete">
            Cancel
        <!-- <mat-icon>delete</mat-icon> -->
    </button>
        <div>
            <button  mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="createMessage()"
                    class="save-button partzbutton"
                    [disabled]="composeForm.invalid || sent"
                    aria-label="SAVE">
                Send
            </button>
            <!-- <input type="file"  placeholder="Upload file" accept=".pdf,.doc,.docx"> -->
            
            <!-- <button  mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        
    </div>
</div>
