export const environment = {
    production: true,
    hmr       : false,
    // baseUrl: 'https://partzkwestapi.genwebhost.com/api',
    // siteUrl: 'https://partzkwestapp.genwebhost.com',
    baseUrl: 'https://partzkwestapi.seemysite.us/index.php/api',
    siteUrl: 'https://app.partzkwest.com',
    firebase: {
        apiKey: "AIzaSyCsiuERPpIhS6sahw9sKnvhhcIz-iFd3zs",
        authDomain: "partzkwest.firebaseapp.com",
        databaseURL: 'https://partzkwest.firebaseio.com',
        projectId: "partzkwest",
        storageBucket: "partzkwest.appspot.com",
        messagingSenderId: "169855282501",
        appId: "1:169855282501:web:8be929c8699b4ba2a3622c",
        measurementId: "G-QXPER43PCF"
      }

};
