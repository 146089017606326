import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  httpOptions:any;
  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
      //   'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('admin:s#;j!md4C!UUY5E(L*=2')
      })
    };
   }
  login(data){
    return this.http.post(environment.baseUrl+'/app_login',data,this.httpOptions);
  }
  logout(data) {
    return this.http.post(environment.baseUrl + '/app_logout', data, this.httpOptions);
  }
  isOnline(data){
    return this.http.post(environment.baseUrl+'/isOnline',data,this.httpOptions);
  }
  isOnlineSync(data){
    return this.http.post(environment.baseUrl+'/isOnline',data,this.httpOptions).toPromise();
  }
}
