export class FileManagerFakeDb
{
    public static files = [
        {
            'name'     : '201 Cooper Engine 1.6',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        },
        {
            'name'     : '1990 Jeep Wrangler',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        },
        {
            'name'     : '04 4runner 4.0',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        },
        {
            'name'     : '16 740',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        },
        {
            'name'     : '2011 BMW',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        },
        {
            'name'     : '2012 Flat 500',
            'website'     : 'http://www.google.com',
            'owner'    : 'Wreking Yard',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'phone': '345463463',
            'location' : 'My Files > Documents',
            'offline'  : true
        }
    ];

}
