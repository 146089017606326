import { Component, Inject, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { Member } from 'app/main/dashboard/member.model';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';

@Component({
    selector     : 'block-member',
    templateUrl  : './block-member.component.html',
    styleUrls    : ['./block-member.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BlockMemberDialogComponent implements OnInit, OnDestroy
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";
    isblock=false;
    currentMember: Member;
    blocktext="Block";
    company="";
    private _unsubscribeAll: Subject<any>;
    timeout: any = null;
    members:any=Member;

    /**
     * Constructor
     *
     * @param {MatDialogRef<BlockMemberDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<BlockMemberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this._unsubscribeAll = new Subject();
        this.getmembers();
        this.company=localStorage.getItem('CompanyName');

        
    }
    ngOnInit(): void
    {
        // Subscribe to update the current mail
        this._mailService.onCurrentMemberChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(currentMember => {
                this.currentMember = currentMember;
                if(this.currentMember.BlockedMembersID!='' && this.currentMember.BlockedMembersID!=null && typeof this.currentMember.BlockedMembersID!='undefined'){
                    this.blocktext="Unblock";
                }else{
                    this.blocktext="Block";
                }
                
            });
        }
        ngOnDestroy(): void
        {
            // Unsubscribe from all subscriptions
            this._unsubscribeAll.next();
            this._unsubscribeAll.complete();
        }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            members: new FormControl(''),
        });
    }
    getmembers(){
        this._mailService.getMembers()
                 .subscribe((members: any) => {
                     this.members=members;
                     this.cleanList(members);
                 });
     
     }
     cleanList(members){
        let evilResponseProps = Object.values(members);
        let goodResponse = [];
        for (var prop in evilResponseProps) {
            goodResponse.push(evilResponseProps[prop]);
        }
        this.memberslist = goodResponse;
     }
blockMembers(e, mid,blocktext){
    // this.formData.append('members', this.composeForm.value.members);
    this.formData.append('member', mid);
    this.formData.append('blockedBy', localStorage.getItem('id'));
    this.formData.append('type', blocktext);
    this._mailService.blockMembers(this.formData)
            .subscribe((members: any) => {
                setTimeout (() => {
                    this.getmembers();
                    alert('Member blocked successfully');
                 }, 1000);
               
            });
}
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
    }
    search(e): void
    {
        let value=e.target.value;
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
                    if(typeof value!='undefined'){
                        // console.log(this.members,' le members');
                            let m = FuseUtils.filterArrayByString($this.members, value);
                            $this.cleanList(m);
                    }
    }, 300);
    }
}
