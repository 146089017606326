<div class="dialog-content-wrapper">
        <div class="dialog-toolbar">
                <div class="custom-column column3">
                    <span class="site-title">Partz Kwest</span>
                </div>
                <div class="custom-column column3">
                    <span class="company-info">{{company}} - Search</span>
                </div>
                <div class="custom-column column3">
                        <div class="close-DB"  (click)="matDialogRef.close()">
                                <button  mat-icon-button
                                aria-label="Delete"
                                matTooltip="Delete">
                            <mat-icon>close</mat-icon>
                            </button>
                        </div>
                </div>
            </div>
    
        <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    
            <form name="composeForm" [formGroup]="composeForm" class="compose-form datetime-form" fxLayout="column" fxFlex>
    
                <mat-form-field appearance="outline">
                        
                    <mat-label>Select start</mat-label>
                          <input matInput name="startdatetime" formControlName="startdatetime" placeholder="Start Date/Time" required type="date">
                          <!-- <mat-date-range-input formControlName="date">
                                <input matStartDate placeholder="Start date">
                                <input matEndDate placeholder="End date">
                              </mat-date-range-input> -->
                            <mat-error>Date/Time is required!</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 5px;">
                        
                        <mat-label>Select end date</mat-label>
                              <input matInput name="enddatetime" placeholder="End Date/Time" formControlName="enddatetime" required type="date">
                              <!-- <mat-date-range-input formControlName="date">
                                    <input matStartDate placeholder="Start date">
                                    <input matEndDate placeholder="End date">
                                  </mat-date-range-input> -->
                                <mat-error>Date/Time is required!</mat-error>
                    </mat-form-field>
            </form>
        </div>
    
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button mat-raised-button mat-dialog-close
                        color="accent"
                        (click)="triggerSearch()"
                        class="save-button"
                        [disabled]="composeForm.invalid"
                        aria-label="SAVE">
                    Search
                </button>
                <a mat-raised-button mat-dialog-close href="{{siteurl}}"
                        color="accent"
                        class="save-button">
                    Clear Filter
            </a>
            </div>
    
            <!-- <button mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
                    aria-label="Delete"
                    matTooltip="Delete">
                <mat-icon>delete</mat-icon>
            </button> -->
        </div>
    </div>
    