
<ng-container *ngIf="!isDialog" >
        <div class="TopBar">
                <div class="custom-column column3">
                        <span class="site-title">Partz Kwest</span>
                    </div>
                <div class="custom-column column3 text-center">
                        <span class="site-title">{{company}} </span>
                    </div>
                    <div class="custom-column column3 text-right">
                        <span class="site-title">Toll Free: (833) 381-2354</span>
                    </div>
                    <div class="clearfx"></div>
        </div>
        <toolbar
                 >
        </toolbar>
        <div *ngIf="currentMail" lt-md class="arrow_back">
                <button mat-icon-button (click)="deselectCurrentMail()">
                    <mat-icon class="secondary-text">arrow_back</mat-icon>
                </button>
            </div>
    </ng-container>
    
    <div class="dialog-toolbar" *ngIf="isDialog">
        <div class="custom-column column3">
            <span class="site-title">Partz Kwest</span>
        </div>
        <div class="custom-column column3">
            <span class="company-info">{{company}} - {{pagetitle}}</span>
        </div>
        <div class="custom-column column3">
                <div class="close-DB"  (click)="closeDialog()">
                        <button  mat-icon-button
                        aria-label="Delete"
                        matTooltip="Delete">
                    <mat-icon>close</mat-icon>
                    </button>
                </div>
        </div>
        <div *ngIf="currentMail" lt-md class="arrow_back">
            <button mat-icon-button (click)="deselectCurrentMail()">
                <mat-icon class="secondary-text">arrow_back</mat-icon>
            </button>
        </div>
    </div>
    
<div id="mail" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar *ngIf="!isDialog" class="sidebar mail-main-sidebar" name="mail-main-sidebar" position="left" lockedOpen="gt-sm">
        <mail-main-sidebar></mail-main-sidebar>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center dashboard-main">
        <div class="content-card" [ngClass]="{'current-mail-selected':currentMail}">
            <div class="content maillist-container" fxLayout="row" [ngClass]="{'member-content':!dashboard}" >
                
                
                <mail-list-dialog class="resize-element" *ngIf="dashboard && !isprint"></mail-list-dialog>
               
                <mail-details-dialog *ngIf="dashboard" fxFlex></mail-details-dialog>
                <member-list *ngIf="!dashboard && showMemberList && !createMember" fusePerfectScrollbar fxFlex></member-list>
                <member-details *ngIf="!dashboard" fxFlex></member-details>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
