import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { environment } from 'environments/environment';


@Component({
    selector     : 'contact',
    templateUrl  : './contact.component.html',
    styleUrls    : ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactDialogComponent
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";
    company="";

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<ContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this.company=localStorage.getItem('CompanyName');
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            member_name: new FormControl(''),
            email: new FormControl(''),
            subject: new FormControl(''),
            comment: new FormControl(''),
        });
    }
submit(){
    var data={
        member_name:this.composeForm.value.member_name,
        email:this.composeForm.value.email,
        subject:this.composeForm.value.subject,
        comment:this.composeForm.value.comment
    };
    this.formData.append('member_name', this.composeForm.value.member_name);
    this.formData.append('email', this.composeForm.value.email);
    this.formData.append('subject', this.composeForm.value.subject);
    this.formData.append('comment', this.composeForm.value.comment);
    if (confirm('Are you sure?')) {
        alert('Email has been sent! Our support agent will contact you soon.');
        this._mailService.contact(this.formData)
                 .subscribe((members: any) => {
                    
         });
      }
    
}
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    resolved(captchaResponse: string) {
        console.log(`Resolved captcha with response: ${captchaResponse}`);
      }
}
