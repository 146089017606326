import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { MailService } from 'app/main/dashboard/dashboard.service'
import {environment} from 'environments/environment';
// import * as bootstrap from 'bootstrap';
declare var $: any;
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  clientoken = '';
  res: any;
  mcount=0;
  acount=0;
  httpOptions:any;
  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient, private MailService:MailService) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //     console.log(_messaging);
    //   }
    // )
    this.httpOptions = {
      headers: new HttpHeaders({
      //   'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('admin:s#;j!md4C!UUY5E(L*=2')
      })
    };
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.clientoken = token;
        localStorage.setItem('fcm_token', this.clientoken);
        //   this.listalerts(this.clientoken).subscribe(res => {
        //     console.log(res);
        //  });
        console.log('token: '+token);
        this.saveToken(token).subscribe(res => {
          // console.log(res);
        });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    console.log('aya hy');
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.res = payload;
        // alert('hellloooo');
        // this.savealert(this.clientoken, this.res.notification.title, this.res.notification.body).subscribe(res => {
        //   console.log(res);
        // });
        // $('.foster.alert').removeClass('read');
        // $('.foster.alert').addClass('unread');
        this.MailService.getMails();
        console.log(" hellooww  hawarrr new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
  savealert(token, title, content) {
    console.log('savealert');
    // return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/create_app_notification?token=' + token + '&title=' + title + '&content=' + content);
  }
  saveToken(token) {
    console.log('savetoken', token);
    return this.http.get(environment.baseUrl+'/saveToken/' + token+'/'+localStorage.getItem('id'), this.httpOptions);
  }

}