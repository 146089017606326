import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { DataService } from 'app/services/data.service';

import { AppComponent } from 'app/app.component';
import { LoginComponent } from 'app/main/login/login.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

// Firebase


import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from "./services/messaging.service";
import { environment } from '../environments/environment';
import { AsyncPipe } from "../../node_modules/@angular/common";
import { MailService } from './main/dashboard/dashboard.service';

import { RecaptchaModule ,RecaptchaFormsModule} from "ng-recaptcha";
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';



const appRoutes: Routes = [
    {
        path        : 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path        : 'dashboard',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.MailModule)
    },
    {
        path        : 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
    {
        path        : 'login',
        component: LoginComponent
    },
    {
        path        : 'logout',
        component: LoginComponent
    },
    {
        path: 'allowNotification',
        component: LoginComponent
    },
    {
        path      : '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [AppComponent, LoginComponent],
    imports: [
        RecaptchaFormsModule,
        RecaptchaModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        RouterModule.forRoot(appRoutes, { useHash: true }),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,

        // AngularFirestoreModule, // Only required for database features
        // AngularFireStorageModule, // Only required for storage features
        // ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: environment.production }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production
        }),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase)
    ],
    bootstrap: [AppComponent],
    providers: [MessagingService, AsyncPipe, MailService]
})
export class AppModule {}
