<mat-toolbar class="p-0 mat-elevation-z1" *ngIf="company!=''">

        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
    
                <button mat-icon-button class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('mail-main-sidebar')" fxShow.lt-lg>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>
    
                <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.lt-lg></div>
    
                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                    <div class="logo ml-16">
                        <img class="logo-icon" src="assets/images/logos/logo-half.png">
                    </div>
                </div>
    
                <div class="px-8 px-md-16 shortcuts-custom" fxHide.lt-md>
                    <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
                    <ul id="nav">
                        <li><a href="#">File</a>
                            <ul>
                                <li><a [routerLink]="['/logout']">Exit</a></li>
                            </ul> 
                        </li>
                        <li><span>Message</span>
                            <ul>
                                <li><span (click)="composeDialog()" >Parts Search</span></li>
                                <li><a (click)='privateMessage()'>Private Message</a></li>
                                <li><span (click)="myMessages('messages')">My Messages</span></li>
                                <li><span (click)="myMessages('orders')">My Orders</span></li>
                                <li><span (click)="myMessages('reports')">My Reports</span></li>
                                <li><span (click)="myMessages('invoices')">My Invoices</span></li>
                            </ul> 
                        </li>
                        <li><a href="#">Members</a>
                            <ul>
                                <li><span (click)='openMembersList()'>Member list</span></li>
                                <li><span (click)='reportMember()'>Report a Member</span></li>
                                <li><span (click)='blockMember()'>Block a Member</span></li>
                                <li><span (click)='addAMember()' *ngIf="role==1 || role==2">Add a Member</span></li>
                                <li><span (click)='suspendAMember()' *ngIf="role==1 || role==2">Suspend a Member</span></li>
                            </ul> 
                        </li>
                        <li><a href="#">Shops</a>
                         <ul>
                                <li><span (click)='openMembersList("shop")'>Shop list</span></li>
                                <li><span (click)='reportMember()'>Report a Shop</span></li>
                                <li><span (click)='blockMember()'>Block a Shop</span></li>
                                <li><span (click)='addAMember("shop")' *ngIf="role==1 || role==2">Add a Shop</span></li>
                                <li><span (click)='suspendAMember("shop")' *ngIf="role==1 || role==2">Suspend a Shop</span></li>
                            </ul>
                        </li>
                        <li><a href="#">Search</a>
                         <ul>
                                <li><span (click)="customSearch('member')">Search by Member</span></li>
                                <li><span (click)="customSearch('datetime')">Search by Date</span></li>
                                <li><span (click)="customSearch('datetime')">Search by Time</span></li>
                            </ul>
                        </li>
                        <li><a href="#">Support</a>
                         <ul>
                                <li><span (click)="openTicket()">Open a Ticket</span></li>
                                <li><span (click)="openPolicy()">Policy and Procedures</span></li>
                                <li><span (click)="openContact()">Contact Us</span></li>
                                <li><span >About Us</span></li>
                            </ul>
                        </li>
                    </ul>
        
                </div>
    
            </div>
    
            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
    
                <!-- <button mat-button [matMenuTriggerFor]="userMenu"
                        class="user-button">
                        <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="avatar mr-0 mr-sm-16" src="{{image}}">
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{company}}</span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button> -->
    
                <!-- <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
    
                    <button mat-menu-item class="unlink">
                        <mat-icon>account_circle</mat-icon>
                        <span><a class="unlink">My Profile</a></span>
                    </button>
    
                    <button mat-menu-item class="unlink">
                        <mat-icon>mail</mat-icon>
                        <span><a class="unlink" [routerLink]="['/dashboard']">Inbox</a></span>
                    </button>
    
                    <button mat-menu-item class="unlink">
                        <mat-icon>exit_to_app</mat-icon>
                        <span><a class="unlink" [routerLink]="['/logout']">Logout</a></span>
                    </button>
    
                </mat-menu> -->
    
                <!-- <div class="toolbar-separator"></div> -->
    
                <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->
    
                <!-- <div class="toolbar-separator"></div> -->
     
                <!-- <button mat-button fxHide fxShow.gt-xs
                        class="language-button"
                        [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                    </div>
                </button> -->
    
                <!-- <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    
                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                            <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                                 <span class="iso">{{lang.title}}</span>
                        </span>
                    </button>
    
                    <button mat-menu-item [routerLink]="'/components/multi-language'">
                            <div fxLayout="row" fxLayoutAlign="start center">
                            <span>Learn more</span>
                        </div>
                    </button>
    
                </mat-menu> -->
    
                <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->
    
                <!-- <button mat-icon-button fxHide.gt-md
                        class="chat-panel-toggle-button"
                        (click)="toggleSidebarOpen('chatPanel')"
                        aria-label="Toggle chat panel">
                    <mat-icon class="secondary-text">chat</mat-icon>
                </button> -->
    
                <div class="toolbar-separator" fxHide.gt-md></div>
    
                <!-- <button mat-icon-button
                        class="quick-panel-toggle-button"
                        (click)="toggleSidebarOpen('quickPanel')"
                        aria-label="Toggle quick panel">
                    <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                </button> -->
    
                <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
    
                <!-- <button mat-icon-button class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                        <mat-icon class="secondary-text">menu</mat-icon>
                </button> -->
    
            </div>
    
        </div>
    
    </mat-toolbar>
    