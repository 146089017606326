import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { FuseUtils } from '@fuse/utils';
import { environment } from 'environments/environment';

import { Mail } from 'app/main/dashboard/dashboard.model';
import { Member } from 'app/main/dashboard/member.model';
import { FuseConfigService } from '@fuse/services/config.service';
declare var $: any;


@Injectable()
export class MailService implements Resolve<any> {
    mails: Mail[];
    mails_private: Mail[];
    Members: Member[];
    data = {
        ForwardedFrom: "",
        Title: "",
        Content: "",
        RootMessageID: "",
        type: "",
        recpID: "",
        members: "",
        isprivate: false,
        isreport: false,
        UserID: localStorage.getItem("id")
    };
    searchitems = {
        members: "",
        startdatetime: "",
        enddatetime: "",
        currentUser: localStorage.getItem("id"),
        isreport: false,
        isinvoicelist: false
    };
    getCOmmentsArgs = {
        MessageID: "",
        recpid: "",
        CreateBy: "",
        currentUser: ""
    };
    currentTitle = "";
    currentMemberIndex = 0;
    messageContext = "Message";
    RootMessageID: any = 0;
    ForwardedFrom: any = "";
    currentContent = "";
    selectedMails: Mail[];
    currentMail: Mail;
    currentMember: Member;
    previousMember: Member;
    nextMember: Member;
    searchText = "";
    fuseConfig: any;
    folders: any[];
    filters: any[];
    labels: any[];
    routeParams: any;
    formData: FormData = new FormData();
    onMailsChanged = new BehaviorSubject<any>("");
    onMailsChanged_private = new BehaviorSubject<any>("");
    onCommentsChanged = new BehaviorSubject<any>("");
    onMembersChanged = new BehaviorSubject<any>("");
    onMailsChanged$ = this.onMailsChanged.asObservable();
    onSelectedMailsChanged: BehaviorSubject<any>;
    onSelectedMemberChanged: BehaviorSubject<any>;
    onCurrentMailChanged: BehaviorSubject<any>;
    onCurrentMemberChanged: BehaviorSubject<any>;
    onPreviousMemberChanged: BehaviorSubject<any>;
    onNextMemberChanged: BehaviorSubject<any>;
    onIndexChanged: BehaviorSubject<any>;
    onFoldersChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onLabelsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    oncreateMember: BehaviorSubject<any>;
    onsuspendMember: BehaviorSubject<any>;
    onChildLoad: BehaviorSubject<any>;
    onDialogChange: BehaviorSubject<any>;
    loading_dialog_mails: BehaviorSubject<any>;
    isFiltering: BehaviorSubject<any>;
    noticount: BehaviorSubject<any>;
    noticount2: BehaviorSubject<any>;
    reportTabs: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any>;
    memberview = false;
    isprivate = false;
    multiple = true;
    isMailDialog = false;
    user_id: any = null;
    openthisMember = null;
    pagetitle = "";
    popupTitle = "";
    isFilteredMain = false;
    searchedMemberText = "";
    isShopList = false;
    isprint = false;
    isinvoice = false;
    isinvoicelist = false;
    isreport = false;
    currentInvoice = null;
    invoiceDialog = null;
    page = 1;
    httpOptions: any;
    createMember = false;
    get_messges: any;
    get_messges_dialog: any;
    loading_child = false;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private _fuseConfigService: FuseConfigService
    ) {
        // Set the defaults
        this.selectedMails = [];
        this.onMailsChanged = new BehaviorSubject([]);
        this.onMailsChanged_private = new BehaviorSubject([]);
        this.onMembersChanged = new BehaviorSubject([]);
        this.onCommentsChanged = new BehaviorSubject([]);
        this.onSelectedMemberChanged = new BehaviorSubject([]);
        this.onSelectedMailsChanged = new BehaviorSubject([]);
        this.noticount = new BehaviorSubject([]);
        this.noticount2 = new BehaviorSubject([]);
        this.reportTabs = new BehaviorSubject([]);
        this.onCurrentMailChanged = new BehaviorSubject([]);
        this.onCurrentMemberChanged = new BehaviorSubject([]);
        this.onPreviousMemberChanged = new BehaviorSubject([]);
        this.onIndexChanged = new BehaviorSubject([]);
        this.onNextMemberChanged = new BehaviorSubject([]);
        this.onFoldersChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onLabelsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject("");
        this.oncreateMember = new BehaviorSubject("");
        this.onsuspendMember = new BehaviorSubject("");
        this.onChildLoad = new BehaviorSubject("");
        this.onDialogChange = new BehaviorSubject("");
        this.loading_dialog_mails = new BehaviorSubject(true);
        this.isFiltering = new BehaviorSubject(false);
        this._unsubscribeAll = new Subject();
        this.isprivate = false;
        this.multiple = true;
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;
            });
        this.httpOptions = {
            headers: new HttpHeaders({
                //   'Content-Type':  'application/json',
                Authorization: "Basic " + btoa("admin:s#;j!md4C!UUY5E(L*=2")
            })
        };
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getFolders(),
                this.getFilters(),
                this.getLabels(),
                this.routeParams.folderHandle == "members"
                    ? this.getDashboardMembers()
                    : this.getMails()
            ]).then(() => {
                if (this.routeParams.folderHandle != "members") {
                    if (this.routeParams.mailId) {
                        if (this.page == 1) {
                            this.setCurrentMail(this.routeParams.mailId);
                        }
                    } else {
                        this.setCurrentMail(null);
                    }

                    this.onSearchTextChanged.subscribe(searchText => {
                        if (searchText !== "") {
                            this.searchText = searchText;
                            this.getMails();
                        }
                    });
                }

                resolve();
            }, reject);
        });
    }

    /**
     * Get all folders
     *
     * @returns {Promise<any>}
     */
    getFolders(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/mail-folders")
                .subscribe((response: any) => {
                    this.folders = response;
                    this.onFoldersChanged.next(this.folders);
                    resolve(this.folders);
                }, reject);
        });
    }

    /**
     * Get all filters
     *
     * @returns {Promise<any>}
     */
    getFilters(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/mail-filters")
                .subscribe((response: any) => {
                    this.filters = response;
                    this.onFiltersChanged.next(this.filters);
                    resolve(this.filters);
                }, reject);
        });
    }

    /**
     * Get all labels
     *
     * @returns {Promise<any>}
     */
    getLabels(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/mail-labels")
                .subscribe((response: any) => {
                    this.labels = response;
                    this.onLabelsChanged.next(this.labels);
                    resolve(this.labels);
                }, reject);
        });
    }

    /**
     * Get all mails
     *
     * @returns {Promise<Mail[]>}
     */
    playAudio() {
        console.log("ding");
        let audio = new Audio();
        audio.src = "../../../assets/audio/ding.mp3";
        audio.load();
        audio.play();
    }
    getMails(search?, page?, requestsource?): Promise<Mail[]> {
        if (page != "" && page != null && typeof page != "undefined") {
            this.page = page;
        } else {
            this.page = 1;
        }
        if (page == "firebase") {
            this.playAudio();
        }
        if (typeof this.routeParams != "undefined") {
            if (this.routeParams.labelHandle) {
                return this.getMailsByLabel(this.routeParams.labelHandle);
            }

            if (this.routeParams.filterHandle) {
                return this.getMailsByFilter(this.routeParams.filterHandle);
            }
        }
        if (search != "" && typeof search != "undefined") {
            this.searchText = search;
        }

        return this.getMailsByFolder(this.routeParams, requestsource);
    }
    getMails_dialog(search?, page?, requestsource?): Promise<Mail[]> {
        if (page != "" && page != null && typeof page != "undefined") {
            this.page = page;
        } else {
            this.page = 1;
        }
        if (page == "firebase") {
            this.playAudio();
        }
        // if(typeof this.routeParams!='undefined'){
        // if ( this.routeParams.labelHandle )
        //     {
        //         return this.getMailsByLabel(this.routeParams.labelHandle);
        //     }

        //     if ( this.routeParams.filterHandle )
        //     {
        //         return this.getMailsByFilter(this.routeParams.filterHandle);
        //     }
        // }
        if (search != "" && typeof search != "undefined") {
            this.searchText = search;
        }
        console.log("getMails_dialog");
        return this.getMailsByFolder_dialog(this.routeParams, requestsource);
    }
    getReportConversations(report_id) {
        var url = this.getMessagesUrl(null);
        this.searchitems.isreport = false; // changing it to false just t fetch conversation list of non admin user on reports page
        this._httpClient
            .post(
                url + "?page=1&report_id=" + report_id,
                this.searchitems,
                this.httpOptions
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(reports => {
                this.searchitems.isreport = true; // changing it back to true so it works for fine for reloading report list from firebase
                console.log(reports, "getReportConversations response");
                this.reportTabs.next(reports);
                if ($("#message-info_0").length > 0) {
                    setTimeout(t => {
                        if ($(".reply-list").length > 0) {
                            $("#message-info_0 span").trigger("click");
                        }
                    }, 500);
                }
            });
    }
    /**
     * Get mails by folder
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMessagesUrl(params?, requestsource?) {
        var url = environment.baseUrl + "/get_messages";
        // if(typeof params !='undefined'){
        if (requestsource == "MyMessages") {
            // to save messages list for dialog
            url = url + "/" + localStorage.getItem("id");
        } else if (
            this.user_id != "" &&
            typeof this.user_id != "undefined" &&
            this.user_id != null
        ) {
            // if(params.mailId!="" && typeof params.mailId!='undefined' && params.mailId!=null){
            // console.log(this.user_id, 'paramss user_id');
            if (this.user_id == "myorders") {
                this.pagetitle = "My Orders";
                url =
                    url + "/" + localStorage.getItem("id") + "-" + this.user_id;
            } else {
                this.pagetitle = "My Messages";
                if (this.isinvoicelist) {
                    this.pagetitle = "My Invoices";
                } else if (this.isinvoice) {
                    this.pagetitle = "Create Invoice";
                } else if (this.isprint) {
                    this.pagetitle = "Print Message";
                } else if (this.isreport) {
                    this.pagetitle = "My Reports";
                }
                url = url + "/" + localStorage.getItem("id");
            }
        }

        // }
        return url;
    }
    getMailsByFolder_dialog(params?, requestsource?): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            // if(this.page==1){
            //     this.onMailsChanged.next(null);

            // }

            var url = this.getMessagesUrl(params, requestsource);
            if (
                this.get_messges_dialog != null &&
                typeof this.get_messges_dialog != "undefined"
            ) {
                // console.log(get_messges.unsubscribe());
                // this.get_messges_dialog.unsubscribe();
            }
            console.log("getMailsByFolder_dialog 1", this.mails_private);
            this.get_messges_dialog = this._httpClient
                .post(
                    url + "?page=" + this.page,
                    this.searchitems,
                    this.httpOptions
                )
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((mails: any) => {
                    // if(mails!=null && typeof mails !='undefined' && mails.length>0){
                    //     if((mails[0]['mode']=='private' && !this.isMailDialog) || (mails[0]['mode']=='main' && this.isMailDialog)){
                    //         return false;
                    //     }
                    // }
                    // console.log('getMailsByFolder', this.page,mails);
                    var temp = mails;
                    if (this.page > 1) {
                        console.log(this.page, "hrere dialog");
                        var m = [];
                        this.loading_dialog_mails.next(false);

                        {
                            for (
                                var i = 0;
                                i < this.mails_private.length;
                                i++
                            ) {
                                m[i] = this.mails_private[i];
                            }
                            for (var j = 0; j < temp.length; j++) {
                                m[i + j] = temp[j];
                            }
                            this.mails_private = m;
                        }
                    } else {
                        {
                            this.mails_private = mails.map(mail => {
                                return new Mail(mail);
                            });
                        }
                    }

                    this.mails_private = FuseUtils.filterArrayByString(
                        this.mails_private,
                        this.searchText
                    );
                    //if(requestsource=='MyMessages' || this.isMailDialog){
                    this.onMailsChanged_private.next(this.mails_private);
                    // }
                    console.log(
                        "I am here checkong onMailsChanged_private",
                        this.mails_private,
                        this.page,
                        this.isprint,
                        $(".current-mail").length
                    );

                    if (!window.matchMedia("(max-width: 960px)").matches) {
                        // console.log('desktop device');
                        // if($('.current-mail').length>0){
                        if (
                            !this.isprint &&
                            this.page == 1 &&
                            this.mails_private.length > 0
                        ) {
                            {
                                console.log("I am here checkong length");
                                this.setCurrentMail_dialog(
                                    this.mails_private[0].MessageID
                                );
                            }
                        }
                        // }
                    } else {
                        console.log(
                            "setCurrentMail dont work in mobile device"
                        );
                    }

                   
                    setTimeout(t => {
                        if ($(".reply-list").length > 0) {
                            // console.log("is ready scroll");
                            // $('.reply-list').scrollTop($('.reply-list')[0].scrollHeight);
                        }
                    }, 200);
                    {
                        resolve(this.mails_private);
                    }
                }, reject);
            console.log("getMailsByFolder_dialog 1 end");
        });
    }
    getMailsByFolder(params?, requestsource?): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            // if(this.page==1){
            //     this.onMailsChanged.next(null);

            // }

            var url = this.getMessagesUrl(params, requestsource);
            if (
                this.get_messges != null &&
                typeof this.get_messges != "undefined"
            ) {
                // console.log(get_messges.unsubscribe());
                // this.get_messges.unsubscribe();
            }
            this.get_messges = this._httpClient
                .post(
                    url + "?page=" + this.page,
                    this.searchitems,
                    this.httpOptions
                )
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((mails: any) => {
                    if (
                        mails != null &&
                        typeof mails != "undefined" &&
                        mails.length > 0
                    ) {
                        if (
                            (mails[0]["mode"] == "private" &&
                                !this.isMailDialog) ||
                            (mails[0]["mode"] == "main" && this.isMailDialog)
                        ) {
                            return false;
                        }
                    }
                    // console.log('getMailsByFolder', this.page,mails);
                    var temp = mails;
                    if (this.page > 1) {
                        console.log(this.page, "hrere");
                        var m = [];
                        this.loading_dialog_mails.next(false);

                        {
                            for (var i = 0; i < this.mails.length; i++) {
                                m[i] = this.mails[i];
                            }
                            for (var j = 0; j < temp.length; j++) {
                                m[i + j] = temp[j];
                            }
                            this.mails = m;
                        }
                    } else {
                        {
                            this.mails = mails.map(mail => {
                                return new Mail(mail);
                            });
                        }
                    }

                    this.mails = FuseUtils.filterArrayByString(
                        this.mails,
                        this.searchText
                    );

                    {
                        this.onMailsChanged.next(this.mails);
                    }
                    if (!window.matchMedia("(max-width: 960px)").matches) {
                        // console.log("desktop device");
                        if ($(".current-mail").length == 0) {
                            if (
                                !this.isprint &&
                                this.page == 1 &&
                                this.mails.length > 0
                            ) {
                                {
                                    this.setCurrentMail(
                                        this.mails[0].MessageID
                                    );
                                }
                            }
                        }
                    } else {
                        console.log(
                            "setCurrentMail dont work in mobile device"
                        );
                    }

                    
                    setTimeout(t => {
                        if ($(".reply-list").length > 0) {
                            // console.log("is ready scroll");
                            $(".reply-list").scrollTop(
                                $(".reply-list")[0].scrollHeight
                            );
                        }
                    }, 200);
                    {
                        resolve(this.mails);
                    }
                }, reject);
        });
    }

    /**
     * Get mails by filter
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMailsByFilter(handle): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            // this._httpClient.get('api/mail-mails?' + handle + '=true')
            //     .subscribe((mails: any) => {
            //         this.mails = mails.map(mail => {
            //             return new Mail(mail);
            //         });
            //         this.mails = FuseUtils.filterArrayByString(this.mails, this.searchText);
            //         this.onMailsChanged.next(this.mails);
            //         resolve(this.mails);
            //     }, reject);
        });
    }

    /**
     * Get mails by label
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMailsByLabel(handle): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            // this._httpClient.get('api/mail-labels?handle=' + handle)
            //     .subscribe((labels: any) => {
            //         const labelId = labels[0].id;
            //         this._httpClient.get('api/mail-mails?labels=' + labelId)
            //             .subscribe((mails: any) => {
            //                 this.mails = mails.map(mail => {
            //                     return new Mail(mail);
            //                 });
            //                 this.mails = FuseUtils.filterArrayByString(this.mails, this.searchText);
            //                 this.onMailsChanged.next(this.mails);
            //                 resolve(this.mails);
            //             }, reject);
            //     });
        });
    }

    /**
     * Toggle selected mail by id
     *
     * @param id
     */
    toggleSelectedMail(id): void {
        // First, check if we already have that mail as selected...
        if (this.selectedMails.length > 0) {
            for (const mail of this.selectedMails) {
                // ...delete the selected mail
                if (mail.id === id) {
                    const index = this.selectedMails.indexOf(mail);

                    if (index !== -1) {
                        this.selectedMails.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedMailsChanged.next(this.selectedMails);

                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedMails.push(
            this.mails.find(mail => {
                return mail.id === id;
            })
        );

        // Trigger the next event
        this.onSelectedMailsChanged.next(this.selectedMails);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedMails.length > 0) {
            this.deselectMails();
        } else {
            this.selectMails();
        }
    }

    /**
     * Select mails
     *
     * @param filterParameter
     * @param filterValue
     */
    selectMails(filterParameter?, filterValue?): void {
        this.selectedMails = [];

        // If there is no filter, select all mails
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedMails = this.mails;
        } else {
            this.selectedMails.push(
                ...this.mails.filter(mail => {
                    return mail[filterParameter] === filterValue;
                })
            );
        }

        // Trigger the next event
        this.onSelectedMailsChanged.next(this.selectedMails);
    }

    /**
     * Deselect mails
     */
    deselectMails(): void {
        this.selectedMails = [];

        // Trigger the next event
        this.onSelectedMailsChanged.next(this.selectedMails);
    }

    /**
     * Set current mail by id
     *
     * @param id
     */
    setCurrentMail(id): void {
        // console.log(id);
        // console.log(this.mails);
        this.currentMail = this.mails.find(mail => {
            return mail.MessageID === id;
        });

        this.onCurrentMailChanged.next(this.currentMail);
    }
    setCurrentMail_dialog(id): void {
        // console.log(id);
        // console.log(this.mails);
        this.currentMail = this.mails_private.find(mail => {
            return mail.MessageID === id;
        });

        this.onCurrentMailChanged.next(this.currentMail);
    }
    setCurrentMember(id, index = null): void {
        // console.log(id,index, 'setCurrentMember');
        this.currentMember = this.Members.find(member => {
            return member.MemberID === id;
        });
        this.onCurrentMemberChanged.next(this.currentMember);
        // console.log(this.currentMember, 'current member');
        if (index != null && this.openthisMember == null) {
            this.currentMemberIndex = index;
            this.onIndexChanged.next(index);
            this.setNextMember(this.Members[index + 1].MemberID);
            this.setPreviousMember(this.Members[index - 1].MemberID);
        }
    }
    setNextMember(id): void {
        // console.log(id);
        this.nextMember = this.Members.find(member => {
            return member.MemberID === id;
        });
        // console.log(this.currentMember, 'current member');
        this.onNextMemberChanged.next(this.nextMember);
    }
    setPreviousMember(id): void {
        // console.log(id);
        // console.log(this.Members);
        this.previousMember = this.Members.find(member => {
            return member.MemberID === id;
        });
        // console.log(this.currentMember, 'current member');
        this.onPreviousMemberChanged.next(this.previousMember);
    }

    /**
     * Toggle label on selected mails
     *
     * @param labelId
     */
    toggleLabelOnSelectedMails(labelId): void {
        this.selectedMails.map(mail => {
            const index = mail.labels.indexOf(labelId);

            if (index !== -1) {
                mail.labels.splice(index, 1);
            } else {
                mail.labels.push(labelId);
            }

            this.updateMail(mail);
        });
    }

    /**
     * Set folder on selected mails
     *
     * @param folderId
     */
    setFolderOnSelectedMails(folderId): void {
        this.selectedMails.map(mail => {
            mail.folder = folderId;

            this.updateMail(mail);
        });

        this.deselectMails();
    }

    /**
     * Update the mail
     *
     * @param mail
     * @returns {Promise<any>}
     */
    updateMail(mail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post("api/mail-mails/" + mail.id, { ...mail })
                .subscribe(response => {
                    this.getMails().then(mails => {
                        if (mails && this.currentMail) {
                            if (this.page == 1) {
                                this.setCurrentMail(this.currentMail.id);
                            }
                        }

                        resolve(mails);
                    }, reject);
                });
        });
    }
    composeMail(data) {
        return this._httpClient.post(
            environment.baseUrl + "/create_message",
            data,
            this.httpOptions
        );
    }
    deleteMail(data) {
        return this._httpClient.post(
            environment.baseUrl + "/delete_message",
            data,
            this.httpOptions
        );
    }
    fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            for (var i = 0; i < fileList.length; i++) {
                let file: File = fileList[i];
                console.log(file, "filechange");
                this.formData.append("uploadFile_" + i, file, file.name);
            }
        }
    }
    deleteFile() {
        this.formData.delete("uploadFile_0");
    }
    getBanners() {
        return this._httpClient.get(
            environment.baseUrl + "/getBanners",
            this.httpOptions
        );
    }
    getVIN(id) {
        return this._httpClient.get(
            environment.baseUrl + "/getVIN/" + id,
            this.httpOptions
        );
    }
    composeWithoutConfirm(data, iscomment?) {
        this.triggerCompose(data, iscomment);
    }
    createMessage(data, iscomment?) {
        // if (confirm('Are you sure?')) {
        this.triggerCompose(data, iscomment);
        //   }
    }
    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }
    triggerCompose(data, iscomment?) {
        var RoleName = localStorage.getItem("RoleName");
        var RoleNameClass = localStorage.getItem("RoleNameClass");
        var CreateByID = localStorage.getItem("id");
        // this.formData=new FormData();
        this.formData.append("Title", this.data.Title);
        this.formData.append("Content", this.data.Content);
        this.formData.append("UserID", this.data.UserID);
        this.formData.append("type", this.data.type);
        this.formData.append("RootMessageID", this.data.RootMessageID);
        this.formData.append("ForwardedFrom", this.data.ForwardedFrom);
        this.formData.append("recpID", this.data.recpID);
        this.formData.append("members", this.data.members);
        this.formData.append("token", localStorage.getItem("fcm_token"));
        this.formData.append("isprivate", this.isprivate.toString());
        this.formData.append(
            "isreport",
            typeof data.isreport != "undefined"
                ? data.isreport.toString()
                : false
        );

        // populate feed list with temporary message till the actuall loads with get_mails. Only for new messages
        if (
            this.data.recpID == "" ||
            this.data.recpID == null ||
            typeof this.data.recpID == "undefined"
        ) {
            console.log("mock message");
            var now = $.now();
            var ampm = this.formatAMPM(new Date());
            var d = new Date(now);
            var datetime =
                d.getMonth() +
                "/" +
                (d.getDate() + 1) +
                "/" +
                d.getFullYear() +
                " " +
                ampm;
            var maildata = {
                Title: this.data.Title,
                Content: this.data.Content,
                RootMessageID: this.data.RootMessageID,
                DateCreated: datetime,
                Photo:
                    "https://partzkwestapi.seemysite.us/uploads/logo-website-half.png",
                isRead: 0,
                RoleName: RoleName,
                RoleNameClass: RoleNameClass,
                CreateByID: CreateByID,
                CreateBy: localStorage.getItem("CompanyName")
            };
            var tempMail = new Mail(maildata);
            this.mails.splice(0, 0, tempMail);
            this.onMailsChanged.next(this.mails);
        }
        ///////

        this.composeMail(this.formData).subscribe(selectedMails => {
            // this._mailService.getMails
            if (iscomment) {
                //
                //    if($('.mat-dialog-container .reply-list').length>0){
                //     $('.mat-dialog-container .reply-list').scrollTop($('.mat-dialog-container .reply-list')[0].scrollHeight);
                // }
            } else {
                this.getMails();
            }
        });
    }
    getComments(data) {
        console.log(data, "getcomments");
        return this._httpClient.post(
            environment.baseUrl + "/getComments",
            data,
            this.httpOptions
        );
    }
    isRead(id, data?) {
        var root = 0;
        var CommentBy = 0;
        var CreateBy = 0;
        var mode = "main";
        console.log(data, "data service");
        if (typeof data.rootid != "undefined") {
            root = data.rootid;
        }
        if (typeof data.child_params != "undefined") {
            CommentBy = data.child_params.CommentBy;
            CreateBy = data.child_params.CreateBy;
        }
        if (typeof data.mode != "undefined") {
            mode = data.mode;
        }
        var userid = localStorage.getItem("id");
        var url =
            environment.baseUrl +
            "/readMessage/" +
            id +
            "/" +
            userid +
            "?mode=" +
            mode +
            "&rootid=" +
            root +
            "&CommentBy=" +
            CommentBy +
            "&CreateBy=" +
            CreateBy;
        // if(flag){
        //     url=url+'/'+data.recpid+'/'+data.createby;
        // }
        return this._httpClient.get(url, this.httpOptions);
    }
    readAll() {
        return this._httpClient.get(
            environment.baseUrl + "/readall/" + localStorage.getItem("id"),
            this.httpOptions
        );
    }
    getNotifications() {
        return this._httpClient.get(
            environment.baseUrl +
                "/getNotifications/" +
                localStorage.getItem("id"),
            this.httpOptions
        );
    }
    getMembers() {
        var userid = localStorage.getItem("id");
        return this._httpClient.get(
            environment.baseUrl +
                "/getMembers?current_user=" +
                userid +
                "&x=" +
                Math.random(),
            this.httpOptions
        );
    }
    moveToOrders(data) {
        return this._httpClient.post(
            environment.baseUrl + "/MoveToOrders",
            data,
            this.httpOptions
        );
    }
    deleteOrder(data) {
        return this._httpClient.post(
            environment.baseUrl + "/deleteOrder",
            data,
            this.httpOptions
        );
    }
    createTicket(data) {
        return this._httpClient.post(
            environment.baseUrl + "/createTicket",
            data,
            this.httpOptions
        );
    }
    contact(data) {
        return this._httpClient.post(
            environment.baseUrl + "/contact",
            data,
            this.httpOptions
        );
    }
    blockMembers(data) {
        return this._httpClient.post(
            environment.baseUrl + "/blockMembers",
            data,
            this.httpOptions
        );
    }
    reportMembers(data) {
        return this._httpClient.post(
            environment.baseUrl + "/reportMembers",
            data,
            this.httpOptions
        );
    }
    setSignature(data) {
        return this._httpClient.post(
            environment.baseUrl + "/setSignature",
            data,
            this.httpOptions
        );
    }
    getShopList() {
        return this._httpClient.get(
            environment.baseUrl + "/getShopList",
            this.httpOptions
        );
    }
    createInvoice(data) {
        return this._httpClient.post(
            environment.baseUrl + "/createInvoice",
            data,
            this.httpOptions
        );
    }
    checkNewFeeds(data) {
        return this._httpClient.post(
            environment.baseUrl + "/checkNewFeeds",
            data,
            this.httpOptions
        );
    }
    getDashboardMembers(searchtext?): Promise<Member[]> {
        return new Promise((resolve, reject) => {
            var userid = localStorage.getItem("id");
            this._httpClient
                .get(
                    environment.baseUrl +
                        "/memberslist?current_user=" +
                        userid +
                        "&shoplist=" +
                        this.isShopList,
                    this.httpOptions
                )
                .subscribe((members: any) => {
                    this.Members = members.map(mail => {
                        return new Member(mail);
                    });
                    // console.log(this.searchText, 'searchtest');
                    // this.oncreateMember.next(this.createMember);
                    this.onMembersChanged.next(this.Members);
                    if (
                        this.currentMember != null &&
                        typeof this.currentMember != "undefined"
                    ) {
                        this.setCurrentMember(
                            this.currentMember.MemberID,
                            this.currentMemberIndex
                        );
                    }
                    if (this.openthisMember != null) {
                        console.log(this.openthisMember, "hererere");
                        this.setCurrentMember(this.openthisMember);
                        this.openthisMember = null;
                    }

                    console.log(this.Members[0].MemberID);
                    resolve(this.Members);
                }, reject);
        });
    }
    uploadbanner(data) {
        this.formData.append("sort", data.sort);
        return this._httpClient.post(
            environment.baseUrl + "/uploadBanner",
            this.formData,
            this.httpOptions
        );
    }
    deletebanner(id) {
        return this._httpClient.get(
            environment.baseUrl + "/deleteBanner/" + id,
            this.httpOptions
        );
    }
    get_child_messages(params) {
        return this._httpClient.post(
            environment.baseUrl + "/get_child_messages",
            params,
            this.httpOptions
        );
    }
    updateProfile(data) {
        return this._httpClient.post(
            environment.baseUrl + "/updateProfile",
            data,
            this.httpOptions
        );
    }
    onlineMembers(data) {
        return this._httpClient.post(
            environment.baseUrl + "/onlineMembers",
            data,
            this.httpOptions
        );
    }
    SuspendMember(data) {
        return this._httpClient.post(
            environment.baseUrl + "/SuspendMember",
            data,
            this.httpOptions
        );
    }
    DeletedMember(data) {
        return this._httpClient.post(
            environment.baseUrl + "/DeletedMember",
            data,
            this.httpOptions
        );
    }
}
