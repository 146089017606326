import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { environment } from 'environments/environment';


@Component({
    selector     : 'datetime-search',
    templateUrl  : './datetime-search.component.html',
    styleUrls    : ['./datetime-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DatetimeSearchDialogComponent
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    siteurl="";
    pagetitle="";
    company="";

    /**
     * Constructor
     *
     * @param {MatDialogRef<DatetimeSearchDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<DatetimeSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this.pagetitle=this._mailService.pagetitle;
        this.company=localStorage.getItem('CompanyName');


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createMessage(){
        this._mailService.data.Title=this.composeForm.value.Title;
        this._mailService.data.Content=this.composeForm.value.Content;
        this._mailService.data.type=this._mailService.messageContext;
        this._mailService.data.RootMessageID=this._mailService.RootMessageID;
        this._mailService.data.ForwardedFrom=this._mailService.ForwardedFrom;
        this._mailService.createMessage(this._mailService.data);
    }
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            // from   : new FormControl({
            //     value   : 'johndoe@creapond.com',
            //     disabled: true
            // }),
            // to     : new FormControl(''),
            // cc     : new FormControl(''),
            // bcc    : new FormControl(''),
            startdatetime: new FormControl(''),
            enddatetime: new FormControl('')
        });
    }

    /**
     * Toggle extra to fields
     */
    triggerSearch(){
        this._mailService.searchitems.startdatetime=this.composeForm.value.startdatetime;
        this._mailService.searchitems.enddatetime=this.composeForm.value.enddatetime;
        this._mailService.isFilteredMain=true;
        this._mailService.isFiltering.next(true);
        this._mailService.page=1;
        this._mailService.searchitems.members="";
        this._mailService.searchedMemberText="Send date: "+this.composeForm.value.startdatetime+" - "+this.composeForm.value.enddatetime;
        this._mailService.getMails('',1);
    }
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
    }
}
