export class Mail {
           Title: string;
           Content: string;
           DateCreated: string;
           Photo: string;
           id: string;
           MessageType: string;
           MessageID: string;
           Email: string;
           Website: string;
           Telephone: string;
           CreateBy: string;
           CreateByID: string;
           ForwardedFrom: string;
           CreatedAt: string;
           Invoice: string;
           InvoiceText: string;
           DateLastReplied: string;
           RootMessageID: string;
           rootid: string;
           UserName: string;
           Status: string;
           from: {
               name: string;
               avatar: string;
               email: string;
           };
           to: {
               name: string;
               email: string;
           }[];
           subject: string;
           message: string;
           time: string;
           read: boolean;
           starred: boolean;
           important: boolean;
           hasAttachments: boolean;
           childmessages: any;
           child_params: any;
           invoice_numbers: any;
           invoiceList: any;
           recpID: any;
           attachments: {
               type: string;
               fileName: string;
               preview: string;
               url: string;
               size: string;
           }[];
           labels: string[];
           folder: string;
           isRead: any;
           RoleName: any;
           RoleNameClass: any;
           CommentBy: any;
           CommentRoleName: any;
           CommentRoleNameClass: any;
           CommentUserName: any;
           CommentTelephone: any;
           CommentEmail: any;
           CommentWebsite: any;
           CommentByID: any;
           isMainPhoto: any;
           MemberID: any;
           RecipientName: any;
           RecipientOwner: any;
           Owner: any;
           recipientUserID: any;
           recipientMemberID: any;
           ReportedUserID: any;
           isreport: any;
           mode: any;
           SignatureText: any;
           RootMessageCreateByID: any;

           /**
            * Constructor
            *
            * @param mail
            */
           constructor(mail) {
               this.MessageID = mail.MessageID;
               this.Title = mail.Title;
               this.Photo = mail.Photo;
               this.Website = mail.Website;
               this.Content = mail.Content;
               this.MemberID = mail.MemberID;
               this.MessageType = mail.MessageType;
               this.Email = mail.Email;
               this.Telephone = mail.Telephone;
               this.CreateBy = mail.CreateBy;
               this.CreateByID = mail.CreateByID;
               this.ForwardedFrom = mail.ForwardedFrom;
               this.DateCreated = mail.DateCreated;
               this.CreatedAt = mail.CreatedAt;
               this.Invoice = mail.Invoice;
               this.InvoiceText = mail.InvoiceText;
               this.DateLastReplied = mail.DateLastReplied;
               this.RootMessageID = mail.RootMessageID;
               this.rootid = mail.rootid;
               this.Status = mail.Status;
               this.id = mail.id;
               this.SignatureText = mail.SignatureText;
               this.from = mail.from;
               this.to = mail.to;
               this.subject = mail.subject;
               this.message = mail.message;
               this.time = mail.time;
               this.read = mail.read;
               this.starred = mail.starred;
               this.important = mail.important;
               this.hasAttachments = mail.hasAttachments;
               this.attachments = mail.attachments;
               this.labels = mail.labels;
               this.folder = mail.folder;
               this.childmessages = mail.childmessages;
               this.child_params = mail.child_params;
               this.invoiceList = mail.invoiceList;
               this.recpID = mail.recpID;
               this.isRead = mail.isRead;
               this.RoleName = mail.RoleName;
               this.RoleNameClass = mail.RoleNameClass;
               this.UserName = mail.UserName;
               this.CommentRoleName = mail.CommentRoleName;
               this.CommentRoleNameClass = mail.CommentRoleNameClass;
               this.CommentUserName = mail.CommentUserName;
               this.CommentBy = mail.CommentBy;
               this.CommentByID = mail.CommentByID;
               this.CommentWebsite = mail.CommentWebsite;
               this.CommentEmail = mail.CommentEmail;
               this.CommentTelephone = mail.CommentTelephone;
               this.invoice_numbers = mail.invoice_numbers;
               this.isMainPhoto = mail.isMainPhoto;
               this.RecipientName = mail.RecipientName;
               this.RecipientOwner = mail.RecipientOwner;
               this.Owner = mail.Owner;
               this.recipientMemberID = mail.recipientMemberID;
               this.recipientUserID = mail.recipientUserID;
               this.ReportedUserID = mail.ReportedUserID;
               this.isreport = mail.isreport;
               this.mode = mail.mode;
               this.RootMessageCreateByID = mail.RootMessageCreateByID;
           }

           /**
            * Toggle star
            */
           toggleStar(): void {
               this.starred = !this.starred;
           }

           /**
            * Toggle important
            */
           toggleImportant(): void {
               this.important = !this.important;
           }
       }
