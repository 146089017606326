<div class="dialog-content-wrapper">
    <!-- <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Customer Support Inquiry</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar> -->
    <div class="dialog-toolbar">
            <div class="custom-column column3">
                <span class="site-title">Partz Kwest</span>
            </div>
            <div class="custom-column column3">
                <span class="company-info">Open a Ticket</span>
            </div>
            <div class="custom-column column3">
                    <div class="close-DB" (click)="matDialogRef.close()">
                            <button  mat-icon-button
                            aria-label="Delete"
                            matTooltip="Delete">
                        <mat-icon>close</mat-icon>
                        </button>
                    </div>
            </div>
        </div>
    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form name="composeForm" [formGroup]="composeForm" class="compose-form ticket-popup" fxLayout="column" fxFlex>

            <!-- <mat-form-field appearance="outline"> -->
                <h1>Customer Support Inquiry</h1>
            <p>Please fill out the following form and we will get right back to you.</p>
            <!-- </mat-form-field> -->
            <mat-form-field appearance="outline" class="clear-field">
                <mat-label>Member Name (required)</mat-label>
                <input matInput name="member_name"
                       formControlName="member_name" required>
                <!-- <div ng-show="composeForm.member_name.$error.required">Member name is required</div> -->
            </mat-form-field>
            <div class="clearfix"></div>
            <mat-form-field appearance="outline" class="clear-field">
                <mat-label>Your Email (required)</mat-label>
                <input matInput name="email"
                       formControlName="email" required>
                <!-- <div ng-show="composeForm.email.$error.required">Email is required</div> -->
            </mat-form-field>
            <div class="clearfix"></div>
            <mat-form-field appearance="outline" class="clear-field">
                <mat-label>Your Telephone (required)</mat-label>
                <input matInput name="phone"
                       formControlName="phone" required>
                <!-- <div ng-show="composeForm.phone.$error.required">Phone is required.</div> -->
            </mat-form-field>
            <div class="clearfix"></div>
            <mat-form-field appearance="outline" class="clear-field">
                <mat-label>Subject (required)</mat-label>
                <input matInput name="subject"
                       formControlName="subject" required>
                <!-- <div ng-show="composeForm.subject.$error.required">Subject is required</div> -->
            </mat-form-field>
            <div class="clearfix"></div>
            <mat-form-field appearance="outline" class="clear-field">
                <mat-label>Issue (required)</mat-label>
                <textarea matInput name="issue"
                          formControlName="issue" required
                          rows="6">
                </textarea>
                <!-- <div ng-show="composeForm.issue.$error.required">Issue is required</div> -->
            </mat-form-field>
        </form>
    </div>

   
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div> -->
                <button mat-raised-button 
                color="accent"
                (click)="matDialogRef.close()"
                class="save-button"
                aria-label="SAVE">
            Close
        </button>
            <button mat-raised-button 
                    color="accent"
                    (click)="submit()"
                    class="save-button"
                    [disabled]="composeForm.invalid"
                    aria-label="SAVE">
                Send
            </button>

        <!-- </div> -->

    </div>
</div>
