export class Member
{
    MemberID: string;
    UserID: string;
    Name: string;
    Address: string;
    Phone: string;
    Email: string;
    OpenTimeStart: string;
    OpenTimeEnd: string;
    Owner: string;
    Sales: string;
    DateLastModified: string;
    IsShowShop: string;
    SignatureText: string;
    FaxNumber: string;
    WebSite: string;
    ResaleNumber: string;
    IsOnline: any;
    IsCod: any;
    IsCharge: any;
    IsFree: any;
    BlockedMembersID: any;
    isSuspended: any;
    BlockedUserID: any;
    DateCreated: any;
    UserName: any;
    Password: any;
    PasswordRe: any;
    FileSuffix:any;
    FileSuffixID:any;
    Description:any;

    /**
     * Constructor
     *
     * @param mail
     */
    constructor(mail)
    {
        this.MemberID = mail.MemberID;
        this.UserID = mail.UserID;
        this.Name = mail.Name;
        this.isSuspended = mail.isSuspended;
        this.Address = mail.Address;
        this.Email = mail.Email;
        this.Phone = mail.Phone;
        this.Email = mail.Email;
        this.OpenTimeStart = mail.OpenTimeStart;
        this.OpenTimeEnd = mail.OpenTimeEnd;
        this.Owner = mail.Owner;
        this.Sales = mail.Sales;
        this.DateLastModified = mail.DateLastModified;
        this.IsShowShop = mail.IsShowShop;
        this.SignatureText = mail.SignatureText;
        this.FaxNumber = mail.FaxNumber;
        this.WebSite = mail.WebSite;
        this.ResaleNumber = mail.ResaleNumber;
        this.IsOnline = mail.IsOnline;
        this.IsCod = mail.IsCod;
        this.IsCharge = mail.IsCharge;
        this.IsFree = mail.IsFree;
        this.Password = mail.Password;
        this.PasswordRe = mail.PasswordRe;
        this.BlockedMembersID = mail.BlockedMembersID;
        this.BlockedUserID = mail.BlockedUserID;
        this.Description = mail.Description;
        this.DateCreated = mail.DateCreated;
        this.UserName = mail.UserName;
        this.FileSuffix = mail.FileSuffix;
        this.FileSuffixID = mail.FileSuffixID;
    }
}
