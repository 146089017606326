import { Component, Inject, ViewEncapsulation , ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { take, takeUntil } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
    selector     : 'mail-compose',
    templateUrl  : './compose.component.html',
    styleUrls    : ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComposeDialogComponent
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    fileslist:any=[];
    memberslist:any=[];
    isforward:any=false;
    ismemberReply:any=false;
    heading="New";
    isprivate=false;
    multiple=false;
    pagetitle="";
    filteredlist:any=[];
    company="";
    sent=false;
   /** control for the MatSelect filter keyword */
   public bankFilterCtrl: FormControl = new FormControl();
 
   /** list of banks filtered by search keyword */
 
   @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
 
   /** Subject that emits when the component has been destroyed. */
   protected _onDestroy = new Subject<void>();
    /**
     * Constructor
     *
     * @param {MatDialogRef<DashboardComposeDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<DashboardComposeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        this.isprivate=this._mailService.isprivate;
        this.multiple=this._mailService.multiple;
        this.company=localStorage.getItem('CompanyName');
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.getmembers();
        console.log(this._mailService.messageContext);
        if(this._mailService.messageContext=='Forward'){
            this.isforward=true;
            this.ismemberReply=false;
            this.heading="Forward";
        }else{
            if(this._mailService.messageContext=='Reply'){
                this.heading="Reply";
            }else{
                this.heading="New";
            }
            // if(this._mailService.messageContext=='Resend'){
            //     this.ismemberReply=false;
            // }
            if(this._mailService.messageContext=='Resend' && this._mailService.data.recpID!=null && typeof this._mailService.data.recpID!='undefined'){
                this.ismemberReply=false;
            }else{
                this.ismemberReply=false;
            }
            
            this.isforward=false;
        }
        this.pagetitle=this._mailService.popupTitle;
        this.sent = false;
        console.log(this.isforward, this.ismemberReply, this.isprivate, 'checking flags');

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    ngOnInit() {
        // set initial selection
    
        // load the initial bank list
    
        // listen for search field value changes
        
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
              console.log(this.bankFilterCtrl.value);
            this.filteredlist = FuseUtils.filterArrayByString(this.memberslist,  this.bankFilterCtrl.value);
          });
      }
    createMessage(){
        this._mailService.data.Title=this.composeForm.value.Title;
        this._mailService.data.Content=this.composeForm.value.Content;
        this._mailService.data.type=this._mailService.messageContext;
        this._mailService.data.RootMessageID=this._mailService.RootMessageID;
        this._mailService.data.ForwardedFrom=this._mailService.ForwardedFrom;
        if(this.isforward || this.isprivate){
            this._mailService.data.members=this.composeForm.value.members;
        }
        if(this.isprivate){
            this._mailService.data.isprivate=true;
            if(this.composeForm.value.members!=''){
                this._mailService.data.recpID=this.composeForm.value.members;
            }
        }else{
            this._mailService.data.recpID="";
        }
        this._mailService.createMessage(this._mailService.data);
        this.sent=true;
    }
    createComposeForm(): FormGroup
    {
        console.log(this._mailService.currentMember);
        return new FormGroup({
            // from   : new FormControl({
            //     value   : 'johndoe@creapond.com',
            //     disabled: true
            // }),
            // to     : new FormControl(''),
            // cc     : new FormControl(''),
            members    : new FormControl(typeof this._mailService.currentMember!='undefined'? this._mailService.currentMember.UserID:''),
            Title: new FormControl(this._mailService.currentTitle),
            Content: new FormControl(this._mailService.currentContent)
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
        this.populateFileList(event.target.files);
    }
    populateFileList(files){
        if(files.length > 0) {
            for(var i=0;i<files.length;i++){
                let file: File = files[i];
                this.fileslist[i]=files[i];
            }
            console.log(this.fileslist);
        }
    }
    deletefile(){
        this.fileslist=[];
        this._mailService.deleteFile();
    }
    getmembers(){
        this._mailService.getMembers()
                 .subscribe((members: any) => {
                     let evilResponseProps = Object.values(members);
                         let goodResponse = [];
                         for (var prop in evilResponseProps) {
                             goodResponse.push(evilResponseProps[prop]);
                         }
                         this.filteredlist=this.memberslist = goodResponse;
                 });
     
     }
}
