import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if ( environment.production )
{
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
                        var isIOS =
                            /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                            !window.MSStream;
                        if (isIOS) {
                            console.log("This is a IOS device");
                        } else {
                            console.log("This is Not a IOS device");
                            if (
                                "serviceWorker" in navigator &&
                                environment.production
                            ) {
                                navigator.serviceWorker.register(
                                    "/firebase-messaging-sw.js"
                                );
                            }
                        }
                        
