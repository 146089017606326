import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { environment } from 'environments/environment';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
@Component({
    selector     : 'member-search',
    templateUrl  : './member-search.component.html',
    styleUrls    : ['./member-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MemberSearchDialogComponent
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";
    pagetitle="";
    company="";
    filteredlist:any=[];
   /** control for the selected bank */

   /** control for the MatSelect filter keyword */
   public bankFilterCtrl: FormControl = new FormControl();
 
   /** list of banks filtered by search keyword */
 
   @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
 
   /** Subject that emits when the component has been destroyed. */
   protected _onDestroy = new Subject<void>();
    /**
     * Constructor
     *
     * @param {MatDialogRef<MemberSearchDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<MemberSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.getmembers();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this.pagetitle=this._mailService.pagetitle;
        this.company=localStorage.getItem('CompanyName');

    }

    ngOnInit() {
        // set initial selection
    
        // load the initial bank list
    
        // listen for search field value changes
        
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
              console.log(this.bankFilterCtrl.value);
            this.filteredlist = FuseUtils.filterArrayByString(this.memberslist,  this.bankFilterCtrl.value);
          });
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createMessage(){
        this._mailService.data.Title=this.composeForm.value.Title;
        this._mailService.data.Content=this.composeForm.value.Content;
        this._mailService.data.type=this._mailService.messageContext;
        this._mailService.data.RootMessageID=this._mailService.RootMessageID;
        this._mailService.data.ForwardedFrom=this._mailService.ForwardedFrom;
        this._mailService.createMessage(this._mailService.data);
    }
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            members: new FormControl(''),
        });
    }
getmembers(){
   this._mailService.getMembers()
            .subscribe((members: any) => {
                let evilResponseProps = Object.values(members);
                    let goodResponse = [];
                    for (var prop in evilResponseProps) {
                        goodResponse.push(evilResponseProps[prop]);
                    }
                    this.filteredlist=this.memberslist = goodResponse;
            });

}
selectedValue(event){
    this._mailService.searchedMemberText="Members: "+event.source.triggerValue
}
triggerSearch(){
    this._mailService.isFilteredMain=true;'';
    this._mailService.searchitems.enddatetime='';
    this._mailService.searchitems.startdatetime='';
    this._mailService.isFiltering.next(true);
    this._mailService.page=1;
    this._mailService.searchitems.members=this.composeForm.value.members;
    this._mailService.getMails('', 1);
}
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
    }
}
