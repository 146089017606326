<div class="dialog-content-wrapper">
        <div class="dialog-toolbar">
                <div class="custom-column column3">
                    <span class="site-title">Partz Kwest</span>
                </div>
                <div class="custom-column column3">
                    <span class="company-info">{{company}} - Contact us</span>
                </div>
                <div class="custom-column column3">
                        <div class="close-DB" (click)="matDialogRef.close(['delete',composeForm])">
                                <button  mat-icon-button
                                aria-label="Delete"
                                matTooltip="Delete">
                            <mat-icon>close</mat-icon>
                            </button>
                        </div>
                </div>
            </div>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form name="composeForm" [formGroup]="composeForm" class="compose-form contact-popup" >

            <!-- <mat-form-field appearance="outline"> -->
            <div fxlayout="row wrap" class="composeForm-inner">
                <div fxlayout="column" fxflex="100" fxflex.gt-sm="50" fxflex.gt-md="50" fxFlex class="resp-col">
                    <div class="contact-icons">
                        <img src="assets/images/icons/icon1.png"/>
                        <img src="assets/images/icons/icon2.png"/>
                        <img src="assets/images/icons/icon3.png"/>
                    </div>
                    <div class="contact-detail">
                            <h2>Contact Us</h2>
                            <p>Partzkwest</p>
                            <p>2740 Hecienda Blvd<br/> Hecienda Heights CA, 91745</p>
                            <p>Phone</p>
                            <p>1 800 505 9358</p>
                            <p>Email</p>
                            <p>rudedogm@verizon.net</p>
                    </div>
                    
                </div>
                <div fxlayout="column" fxflex="100" fxflex.gt-sm="50" fxflex.gt-md="50" fxFlex class="resp-col">
                    <p>Please fill out the following form fields.</p>
                    <!-- </mat-form-field> -->
                    <mat-form-field appearance="outline">
                        <mat-label>Member Name (required)</mat-label>
                        <input matInput name="member_name"
                               formControlName="member_name" required>
                        <!-- <div ng-show="composeForm.member_name.$error.required">Member name is required</div> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Your Email (required)</mat-label>
                        <input matInput name="email"
                               formControlName="email" required>
                        <!-- <div ng-show="composeForm.email.$error.required">Email is required</div> -->
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Subject (required)</mat-label>
                        <input matInput name="subject"
                               formControlName="subject" required>
                        <!-- <div ng-show="composeForm.subject.$error.required">Subject is required</div> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Comment (required)</mat-label>
                        <textarea matInput name="comment"
                                  formControlName="comment" required
                                  rows="6">
                        </textarea>
                        <!-- <div ng-show="composeForm.issue.$error.required">Issue is required</div> -->
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline"> -->
                        
                        <!-- <div ng-show="composeForm.issue.$error.required">Issue is required</div> -->
                    <!-- </mat-form-field> -->
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        

        <!-- <button mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
                aria-label="Delete"
                matTooltip="Delete">
                <mat-icon>delete</mat-icon>
        </button> -->
        <div>
            <button mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="submit()"
                    class="save-button"
                    [disabled]="composeForm.invalid"
                    aria-label="SAVE">
                Submit
            </button>

        </div>
    </div>
</div>
