<div class="dialog-toolbar">
        <div class="custom-column column3">
            <span class="site-title">Partz Kwest</span>
        </div>
        <div class="custom-column column3">
            <span class="company-info">{{company}} - Report a member</span>
        </div>
        <div class="custom-column column3">
                <div class="close-DB" (click)="matDialogRef.close(['delete',composeForm])">
                        <button  mat-icon-button
                        aria-label="Delete"
                        matTooltip="Delete">
                    <mat-icon>close</mat-icon>
                    </button>
                </div>
        </div>
    </div>
<div class="dialog-content-wrapper">
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
            <form name="composeForm" [formGroup]="composeForm" class="compose-form" >
                <table>
                    <thead class="hideon-mobile">
                        <tr>
                                <th>Date</th>
                                <th>Invoice#</th>
                                <th style="width: 300px;">Select Member</th>
                                <th>PO#</th>
                                <th>Description</th>
                                <th>Total Amount</th>
                                <th></th>
                        </tr>
                    
                    </thead>
                    <tbody>
                    <tr>
                            <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput name="date_1" type="date"
                                               formControlName="date_1" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice#</mat-label>
                                        <input matInput name="invoice_1"
                                               formControlName="invoice_1" required>
                                    </mat-form-field>
                                    </td>
                                    <td style="width: 300px;">
                                    <mat-form-field  appearance="outline">
                    
                                        <mat-label>Select Member</mat-label>
                                        <mat-select
                                            formControlName="member_1" name="member_1">
                                            <mat-option *ngFor="let member of memberslist"  [value]='member.UserID'>
                                                {{member.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select at memeber!</mat-error>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>PO#</mat-label>
                                    <input matInput name="po_1"
                                           formControlName="po_1" required>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput name="description_1" style='height: 18px;'
                                              formControlName="description_1" required
                                              rows="6">
                                    </textarea>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Total Amount</mat-label>
                                    <input matInput name="amount_1" type="number"
                                           formControlName="amount_1" required>
                                </mat-form-field>
                                <hr class="showon-mobile"/>
                                </td>
                    </tr>
                    <tr  *ngIf="invoice_count>=2">
                            <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput name="date_2" type="date"
                                               formControlName="date_2" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice#</mat-label>
                                        <input matInput name="invoice_2"
                                               formControlName="invoice_2" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field  appearance="outline">
                    
                                        <mat-label>Select Member</mat-label>
                                        <mat-select
                                            formControlName="member_2">
                                            <mat-option *ngFor="let member of memberslist"  [value]='member.UserID'>
                                                {{member.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select at memeber!</mat-error>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>PO#</mat-label>
                                    <input matInput name="po_2"
                                           formControlName="po_2" required>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput name="description_2" style='height: 18px;'
                                              formControlName="description_2" required
                                              rows="6">
                                    </textarea>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Total Amount</mat-label>
                                    <input matInput name="amount_2" type="number"
                                           formControlName="amount_2" required>
                                </mat-form-field>
                                </td>
                                <td>
                                    <span class="delete-invoice" (click)='removeInvoice()'>Delete</span>
                                </td>
                    </tr>
                    <tr  *ngIf="invoice_count>=3">
                            <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput name="date_3" type="date"
                                               formControlName="date_3" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice#</mat-label>
                                        <input matInput name="invoice_3"
                                               formControlName="invoice_3" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field  appearance="outline">
                    
                                        <mat-label>Select Member</mat-label>
                                        <mat-select
                                            formControlName="member_3" name='member_3'>
                                            <mat-option *ngFor="let member of memberslist"  [value]='member.UserID'>
                                                {{member.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select at memeber!</mat-error>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>PO#</mat-label>
                                    <input matInput name="po_3"
                                           formControlName="po_3" required>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput name="description_3" style='height: 18px;'
                                              formControlName="description_3" required
                                              rows="6">
                                    </textarea>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Total Amount</mat-label>
                                    <input matInput name="amount_3" type="number"
                                           formControlName="amount_3" required>
                                </mat-form-field>
                                </td>
                                <td>
                                        <span class="delete-invoice" (click)='removeInvoice()'>Delete</span>
                                        <hr class="showon-mobile"/>
                                    </td>
                    </tr>
                    <tr  *ngIf="invoice_count>=4">
                            <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput name="date_4" type="date"
                                               formControlName="date_4" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice#</mat-label>
                                        <input matInput name="invoice_4"
                                               formControlName="invoice_4" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field  appearance="outline">
                    
                                        <mat-label>Select Member</mat-label>
                                        <mat-select
                                            formControlName="member_4" name='member_4'>
                                            <mat-option *ngFor="let member of memberslist"  [value]='member.UserID'>
                                                {{member.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select at memeber!</mat-error>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>PO#</mat-label>
                                    <input matInput name="po_4"
                                           formControlName="po_4" required>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput name="description_4" style='height: 18px;'
                                              formControlName="description_4" required
                                              rows="6">
                                    </textarea>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Total Amount</mat-label>
                                    <input matInput name="amount_4" type="number"
                                           formControlName="amount_4" required>
                                </mat-form-field>
                                </td>
                                <td>
                                        <span class="delete-invoice" (click)='removeInvoice()'>Delete</span>
                                        <hr class="showon-mobile"/>
                                    </td>
                    </tr>
                    <tr  *ngIf="invoice_count>=5">
                            <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput name="date_5" type="date"
                                               formControlName="date_5" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice#</mat-label>
                                        <input matInput name="invoice_5"
                                               formControlName="invoice_5" required>
                                    </mat-form-field>
                                    </td>
                                    <td>
                                    <mat-form-field  appearance="outline">
                    
                                        <mat-label>Select Member</mat-label>
                                        <mat-select
                                            formControlName="member_5">
                                            <mat-option *ngFor="let member of memberslist"  [value]='member.UserID'>
                                                {{member.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Select at memeber!</mat-error>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>PO#</mat-label>
                                    <input matInput name="po_5"
                                           formControlName="po_5" required>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput name="description_5" style='height: 18px;'
                                              formControlName="description_5" required
                                              rows="6">
                                    </textarea>
                                </mat-form-field>
                                </td>
                                <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Total Amount</mat-label>
                                    <input matInput name="amount_5" type="number"
                                           formControlName="amount_5" required>
                                </mat-form-field>
                                </td>
                                <td>
                                        <span class="delete-invoice" (click)='removeInvoice()'>Delete</span>
                                        <hr class="showon-mobile"/>
                                    </td>
                    </tr>
                </tbody>
            </table>
        </form>
        <div class="actions">
            <button mat-raised-button
                    color="accent"
                    (click)="addIvoice()"
                    class="save-button"
                    [disabled]="invoice_count==5"
                    aria-label="SAVE">
                Add a Invoice
            </button>
            <button mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="reportMembers()"
                    class="save-button"
                    [disabled]="composeForm.invalid"
                    aria-label="SAVE">
                OK
            </button>
            <button mat-raised-button mat-dialog-close
                    color="accent"
                    (click)="matDialogRef.close(['delete',composeForm])"
                    class="save-button">
                Cancel
            </button>
        </div>
    </div>
    </div>
    