import { Component, Inject, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { Member } from 'app/main/dashboard/member.model';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';

@Component({
    selector     : 'policy-member',
    templateUrl  : './policy.component.html',
    styleUrls    : ['./policy.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PolicyDialogComponent implements OnInit
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";
    isblock=false;
    currentMember: Member;
    blocktext="Block";
    company="";
    private _unsubscribeAll: Subject<any>;
    timeout: any = null;
    members:any=Member;

    /**
     * Constructor
     *
     * @param {MatDialogRef<BlockMemberDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<PolicyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this._unsubscribeAll = new Subject();
        this.company=localStorage.getItem('CompanyName');

        
    }
    ngOnInit(): void
    {
 
        }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */


    /**
     * Toggle extra to fields
     */

}
