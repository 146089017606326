import { Component, Inject, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { Member } from 'app/main/dashboard/member.model';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'report-member',
    templateUrl  : './report-member.component.html',
    styleUrls    : ['./report-member.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReportMemberDialogComponent implements OnInit, OnDestroy
{
    showExtraToFields: boolean;
    composeForm: FormGroup;
    formData:FormData = new FormData();
    memberslist:any=[];
    siteurl="";
    isblock=false;
    currentMember: Member;
    blocktext="Block";
    private _unsubscribeAll: Subject<any>;
    invoice_count=1;
    company="";

    /**
     * Constructor
     *
     * @param {MatDialogRef<ReportMemberDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _mailService: MailService,
        public matDialogRef: MatDialogRef<ReportMemberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        this.siteurl=environment.siteUrl;
        this._unsubscribeAll = new Subject();
        this.getmembers();
        this.company=localStorage.getItem('CompanyName');

        
    }
    ngOnInit(): void
    {
        // Subscribe to update the current mail
        this._mailService.onCurrentMemberChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(currentMember => {
                this.currentMember = currentMember;
                if(this.currentMember.BlockedMembersID!='' && this.currentMember.BlockedMembersID!=null && typeof this.currentMember.BlockedMembersID!='undefined'){
                    this.blocktext="Unblock";
                }else{
                    this.blocktext="Block";
                }
                
            });
        }
        ngOnDestroy(): void
        {
            // Unsubscribe from all subscriptions
            this._unsubscribeAll.next();
            this._unsubscribeAll.complete();
        }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createComposeForm(): FormGroup
    {
        return new FormGroup({
            member_1: new FormControl(''),
            date_1: new FormControl(''),
            invoice_1: new FormControl(''),
            po_1: new FormControl(''),
            amount_1: new FormControl(''),
            description_1: new FormControl(''),
            member_2: new FormControl(''),
            date_2: new FormControl(''),
            invoice_2: new FormControl(''),
            po_2: new FormControl(''),
            amount_2: new FormControl(''),
            description_2: new FormControl(''),
            member_3: new FormControl(''),
            date_3: new FormControl(''),
            invoice_3: new FormControl(''),
            po_3: new FormControl(''),
            amount_3: new FormControl(''),
            description_3: new FormControl(''),
            member_4: new FormControl(''),
            date_4: new FormControl(''),
            invoice_4: new FormControl(''),
            po_4: new FormControl(''),
            amount_4: new FormControl(''),
            description_4: new FormControl(''),
            member_5: new FormControl(''),
            date_5: new FormControl(''),
            invoice_5: new FormControl(''),
            po_5: new FormControl(''),
            amount_5: new FormControl(''),
            description_5: new FormControl(''),
        });
    }
    getmembers(){
        this._mailService.getMembers()
                 .subscribe((members: any) => {
                     let evilResponseProps = Object.values(members);
                         let goodResponse = [];
                         for (var prop in evilResponseProps) {
                             goodResponse.push(evilResponseProps[prop]);
                         }
                         this.memberslist = goodResponse;
                 });
     
     }
     addIvoice(){
         if(this.invoice_count<6){
            this.invoice_count++;
         }else{
             alert('Limit reached');
         }
     }   
     removeInvoice(){
        if(this.invoice_count>1){
            this.invoice_count--;
         }else{
             alert('Limit reached');
         } 
     } 
    reportMembers(){
        // console.log(this.composeForm.value);
        // console.log(this.composeForm.value.length);
        for (var key in this.composeForm.value) {
            // check if the property/key is defined in the object itself, not in parent
            if (this.composeForm.value.hasOwnProperty(key)) {           
                console.log(key, this.composeForm.value[key]);
                this.formData.append(key, this.composeForm.value[key]);
            }
        }
        this.formData.append('CreatedBy', localStorage.getItem('id'));
        this.formData.append('CompanyName', localStorage.getItem('CompanyName'));
        this._mailService.reportMembers(this.formData)
                .subscribe((members: any) => {
                    alert('Members reported successfully');
                });
        // // this.formData.append('members', this.composeForm.value.members);
        // this.formData.append('data', this.composeForm.);
        // this.formData.append('blockedBy', localStorage.getItem('id'));
        // this.formData.append('type', this.blocktext);
        // this._mailService.blockMembers(this.formData)
        //         .subscribe((members: any) => {
        //             this._mailService.getDashboardMembers();
        //             alert('Members blocked successfully');
        //         });
    }
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }
    fileChange(event) {
        this._mailService.fileChange(event);
    }
}
