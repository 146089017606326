import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject, HostListener } from '@angular/core';
import { FormControl,FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { Mail } from 'app/main/dashboard/dashboard.model';
import { Member } from 'app/main/dashboard/member.model';
import { MailService } from 'app/main/dashboard/dashboard.service';
import { DataService } from 'app/services/data.service';

import { locale as english } from 'app/main/dashboard/i18n/en';
import { locale as turkish } from 'app/main/dashboard/i18n/tr';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
// import { setInterval } from 'timers';

@Component({
    selector: "mail-dialog",
    templateUrl: "./dashboard-dialog.component.html",
    styleUrls: ["./dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: MAT_DIALOG_DATA,
            useValue: {}
        },
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ]
})
export class DashboardDialogComponent implements OnInit, OnDestroy {
    hasSelectedMails: boolean;
    isIndeterminate: boolean;
    folders: any[];
    filters: any[];
    labels: any[];
    searchInput: FormControl;
    currentMail: Mail;
    mails: Mail;
    Members: Member;
    dashboard = true;
    isDialog = false;
    user_id: any = null;
    company;
    username;
    phone;
    pagetitle = "";
    showMemberList = true;
    isprint = false;
    invoiceDialog = null;
    page = 1;
    timeout: any = null;
    loading = false;
    loading_mail = false;
    createMember = false;
    deferredPrompt: any;
    reloadmessages:any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MailService} _mailService
     * @param {DataService} _dataService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _mailService: MailService,
        private _dataService: DataService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        private route: ActivatedRoute,
        public matDialogRef: MatDialogRef<DashboardDialogComponent>,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        // Load the translations
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        // Set the defaults
        this.searchInput = new FormControl("");

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.company = localStorage.getItem("CompanyName");
        this.username = JSON.parse(localStorage.getItem("UserName"));
        this.phone = localStorage.getItem("Phone");
        if (this._mailService.openthisMember != null) {
            this.showMemberList = false;
        }
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    // @HostListener('window:beforeunload', ['$event'])
    // beforeunloadHandler(event) {
    //     event.preventDefault();
    //     alert('here');
    // }
    // installApp = async () => {
    //     let deferredPrompt=this.deferredPrompt;
    //     if (deferredPrompt !== null) {
    //         deferredPrompt.prompt();
    //         const { outcome } = await deferredPrompt.userChoice;
    //         if (outcome === 'accepted') {
    //             deferredPrompt = null;
    //         }
    //     }
    // };
    ngOnInit(): void {
        // let deferredPrompt;
        // window.addEventListener('beforeinstallprompt', (e) => {
        //     e.preventDefault();
        //     this.deferredPrompt = e;
        //     console.log(e,'beforeinstallprompt' );
        //     this.installApp();
        // });

        var dataservice = this._dataService;
        var mail_service = this._mailService;
        //  this._mailService.onMailsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(mails => {
        //         this.loading=false;
        //         if($('.current-mail').length==0){
        //             if(!this.isprint && this.page==1 && mails.length>0){
        //                 this._mailService.setCurrentMail(mails[0].MessageID);
        //             }
        //         }
        //         dataservice.isOnline({UserID:localStorage.getItem('id'), isOnline:1}).subscribe(res => {});
        //         console.log('loading false');
        //     });
        this._mailService.onMailsChanged_private
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(mails => {
                if (this.isDialog) {
                    this.loading_mail = false;
                    if ($(".current-mail").length == 0) {
                        if (
                            !this.isprint &&
                            this.page == 1 &&
                            mails.length > 0
                        ) {
                            console.log("I am here checking page");
                            this._mailService.setCurrentMail(
                                mails[0].MessageID
                            );
                        }
                    }
                    // dataservice.isOnline({UserID:localStorage.getItem('id'), isOnline:1}).subscribe(res => {});
                    // console.log('loading false');
                }
            });
        this._mailService.oncreateMember
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(createMember => {
                console.log(createMember + "//createMember");
                this.createMember = createMember;
            });
        this._mailService.onDialogChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(dialog => {
                this.isDialog = dialog;
                this._mailService.isMailDialog = dialog;
            });

        this.route.params.subscribe(routeParams => {
            var url = this.router.url;
            console.log(url);
            if (
                url == "/dashboard/members" ||
                this._mailService.user_id == "members"
            ) {
                // set members view
                this.dashboard = false;
                this._mailService.memberview = true;
                // this._mailService.getDashboardMembers();
            } else {
                this.dashboard = true;
                this._mailService.memberview = false;
                // this._mailService.onMailsChanged.next(null);
                if (this.isDialog) {
                    var mailparam =
                        this._mailService.user_id != null
                            ? this._mailService.user_id
                            : "MyMessages";
                    this._mailService.loading_dialog_mails.next(true);
                    this._mailService.getMails_dialog("", "", mailparam); // mail 1
                }
                this._mailService.onSelectedMailsChanged
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(selectedMails => {
                        this.pagetitle = this._mailService.pagetitle;
                        this.isprint = this._mailService.isprint;
                        this.invoiceDialog = this._mailService.invoiceDialog;

                        setTimeout(() => {
                            this.hasSelectedMails = selectedMails.length > 0;
                            this.isIndeterminate =
                                selectedMails.length !==
                                    this._mailService.mails.length &&
                                selectedMails.length > 0;
                        }, 0);
                    });
                this.searchInput.valueChanges
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        debounceTime(300),
                        distinctUntilChanged()
                    )
                    .subscribe(searchText => {
                        this._mailService.onSearchTextChanged.next(searchText);
                    });
                this._mailService.onCurrentMailChanged
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(currentMail => {
                        if (!currentMail) {
                            this.currentMail = null;
                        } else {
                            this.currentMail = currentMail;
                        }
                    });
            }
        });
        if (!this.isDialog) {
            // temporary start
            //    setInterval(function () {
            //     mail_service.onlineMembers({current_user:localStorage.getItem('id'), isOnline:1})
            //     .subscribe(members => {
            //         mail_service.onMembersChanged.next(members);
            //     });
            //     }, 1200000);
            // temporary end
        }
        this.createMember = this._mailService.createMember;
        console.log(this.createMember + " createMember");
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    ngAfterViewInit() {
        // $(".mail-list").draggable();
        // $(".resize-element").resizable({ handles: "all" });
        $(".resize-element").resizable({
            handles: "e",
            start: function(event, ui) {
                $(".resize-element").css("flex", "auto !important");
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        this._mailService.toggleSelectAll();
    }

    /**
     * Select mails
     *
     * @param filterParameter
     * @param filterValue
     */
    selectMails(filterParameter?, filterValue?): void {
        this._mailService.selectMails(filterParameter, filterValue);
    }

    /**
     * Deselect mails
     */
    deselectMails(): void {
        this._mailService.deselectMails();
    }

    /**
     * Deselect current mail
     */
    deselectCurrentMail(): void {
        this._mailService.onCurrentMailChanged.next(null);
        if (window.matchMedia("(max-width: 960px)").matches) {
            $("mail-list-dialog").show();
        }
    }

    /**
     * Toggle label on selected mails
     *
     * @param labelId
     */
    toggleLabelOnSelectedMails(labelId): void {
        this._mailService.toggleLabelOnSelectedMails(labelId);
    }

    /**
     * Set folder on selected mails
     *
     * @param folderId
     */
    setFolderOnSelectedMails(folderId): void {
        this._mailService.setFolderOnSelectedMails(folderId);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
    closeDialog() {
        clearInterval(this.reloadmessages);
        this._mailService.onMailsChanged.next(null); // reloading main feeds on window close
        this.isDialog=false;
        if (
            this._mailService.get_messges != "" &&
            this._mailService.get_messges != null &&
            typeof this._mailService.get_messges != "undefined"
        ) {
            this._mailService.get_messges.unsubscribe();
        }
        this._mailService.onDialogChange.next(false);
        this._mailService.isreport = false;
        this._mailService.createMember = false;
        this._mailService.oncreateMember.next(false);
        this._mailService.searchitems.isreport = false;
        this._mailService.user_id = null;
        this._mailService.isinvoicelist = false;
        this._mailService.isinvoice = false;
        this._mailService.isprint = false;
        this._mailService.currentInvoice = null;
        this._mailService.searchitems.isinvoicelist = false;
        console.log('testing mails ',this.mails, this._mailService.mails);
        this._mailService.getMails('', 1);
        this._matDialog.closeAll();
        this._mailService.getNotifications().subscribe((notis: any) => {
            console.log(notis);
            this._mailService.noticount2.next(notis);
        });
    }
}
